import {gql} from 'apollo-angular';
import {PERSON_TYPEAHEAD_OBJECT} from '../personTypeAheadFragment';

export const PERSONS_TYPEAHEAD_QUERY = gql`
    query persons(
        $searchParams: PersonSearchInput,
        $limit: Int,
        $offset: Int,
        $orderBy: [OrderByInput]
    ) {
        persons(
            searchParams: $searchParams,
            limit: $limit,
            offset: $offset,
            orderBy: $orderBy
        ) {

            status
            message
            totalCount
            list {
                ... on Person {
                    ...PersonTypeAheadObject
                }
            }
        }
    }
    ${PERSON_TYPEAHEAD_OBJECT}
`;
