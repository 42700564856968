import {gql} from 'apollo-angular';

export const PERSON_COMPANY_AI_FIND_BY_PK = gql`
    query findByPk($id: ID!) {
        findByPk(id: $id) {
            id
            userCompanyId
            personCompanyType
            personCompanyId
            relationType
            status
            email
            phoneNumber
            comments
            createdAt
            updatedAt
            createdBy
            updatedBy
            referrerId
            person {
            ... on Person {
                    id
                    title
                    firstName
                    lastName
                    identificationType
                    identification
                    nationality
                    birthdate
                }
            }
            company {
                ... on Company {
                    id
                    legalName
                    tradename
                    legalIdentification
                }
            }
            addresses {
                ... on Address {
                    id
                    address
                    town
                    postalCode
                    provinceCode
                    countryCode
                    status
                    default
                    addressType
                    personCompanyId
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                }
            }
            referrer {                 
                person {
                    ... on Person {
                        id
                        title
                        firstName
                        lastName
                        identificationType
                        identification
                        nationality
                        birthdate
                    }
                }
            }
        }
    }
  
`;
