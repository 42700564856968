import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Component, ElementRef, inject, OnInit, ViewChildren} from '@angular/core';
import {FormBuilder, FormControlName, FormGroup, ReactiveFormsModule, Validators,} from '@angular/forms';
import {fromEvent, merge, Observable} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';
import {ILogin} from '../../../@interfaces/login.interface';
import {IUser} from '../../../@interfaces/user.interface';
import {UserMessageService} from '../../services/user/user-message.service';
import {UserService} from '../user.service';
import {LocalStorageService} from '../../../services/local-storage.service';
import {Router, RouterLink} from '@angular/router';
import {UserAuthService} from '../../services/user/user-auth.service';
import {AsyncPipe, NgIf} from '@angular/common';
import {MatButton} from '@angular/material/button';
import {MatGridList, MatGridTile} from '@angular/material/grid-list';
import {MatFormField, MatHint, MatLabel, MatSuffix} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {MatCheckbox} from '@angular/material/checkbox';
import {MatIcon} from '@angular/material/icon';
import {RefreshTokenService} from '../../../services/refresh-token.service';
import {COOKIE_OPTIONS, CookieModule, CookieOptionsProvider, CookieService} from 'ngx-cookie';


@Component({
    selector: 'app-user-login-form',
    templateUrl: './user-login-form.component.html',
    styleUrls: ['./user-login-form.component.scss'],
    imports: [
        ReactiveFormsModule,
        RouterLink,
        NgIf,
        RouterLink,
        MatButton,
        MatButton,
        ReactiveFormsModule,
        NgIf,
        RouterLink,
        MatGridTile,
        MatGridTile,
        MatGridList,
        MatSuffix,
        MatFormField,
        MatInput,
        AsyncPipe,
        MatCheckbox,
        MatIcon,
        MatHint,
        MatLabel
    ],
    providers: [CookieService,
        CookieOptionsProvider,
        CookieModule,
        {provide: COOKIE_OPTIONS, useValue: {}}
    ],
    standalone: true
})
export class UserLoginFormComponent implements OnInit {
    // Form related properties
    loginForm: FormGroup; // para gestionar el reactive form

    @ViewChildren(FormControlName, {read: ElementRef})
    formControls: ElementRef[];
    formObj: {}; // para debug y para almacenar el form en local storage
    message: { [key: string]: string } = {}; // objeto de tabla de mensajes de error?
    formStatus: string; // para debuguear
    formFieldAppearance = 'fill'; // standard, fill, outline   //PENDIENTE: Poner esto en un sitio genérico, que obtenga de usrPrefs o default
    formIsEnabled = true;
    user: IUser | undefined; // To store user person details
    loading = false;
    // Lo intenté meter en una función, pero no se ejecuta el match
    formFieldsLayout: any;

    // PENDIENTE: Organizar un poco esto. Es este el sitio adecuado? No debería hacerse a la vez que this.fb.group.
    // PENDIENTE. REPLICADO! Modularizar!
    private validationMessages: {
        // Gestion de mensajes de información al usuario sobre cada campo validado
        [key: string]: { [key: string]: string | { [key: string]: string } };
    };

    private refreshTokenService = inject(RefreshTokenService);

    constructor(
        private fb: FormBuilder,
        // private snackBar: MatSnackBar,
        private breakpointObserver: BreakpointObserver,
        private localStorageService: LocalStorageService,
        private userService: UserService,
        private userMessageService: UserMessageService,
        private router: Router,
        private userAuthService: UserAuthService,
    ) {
        this.validationMessages = this.validationMessagesConfig();
    }

    // PENDIENTE: parece que esto dispara las validaciones al salir de un campo. Se puede centralizar??
    ngAfterViewInit(): void {
        const addBlurs: Observable<any>[] = this.formControls.map(
            (formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur')
        );
        merge(this.loginForm.valueChanges, ...addBlurs)
            .pipe(debounceTime(500))
            .subscribe((value) => {
                this.message = this.invalidInputs(this.loginForm);
            });
    }

    ngOnInit(): void {
        this.formFieldsLayout = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
            map(({matches}) => {
                if (matches) {
                    // console.log("Breakpoints:", Breakpoints);
                    // mobile
                    return {
                        userCode: {cols: 12},
                        password: {cols: 12},
                        rememberMe: {cols: 6},
                    };
                }

                // Default configuracion
                return {
                    userCode: {cols: 12},
                    password: {cols: 12},
                    rememberMe: {cols: 6},
                };
            })
        );
        // Al pinchar el logout enviamos al usuario a esta pagina y eliminamos el token actual
        // this.localStorageService.removeItem("userToken");
        this.userAuthService.deleteUserSessionInfo();

        // TODO ESTO ESTA REPLICADO EN OTROS FORMS!
        // Form configuration
        this.loginFormConfiguration(this.initialDataConfig());

        // Validations
        this.loginForm.valueChanges.subscribe(() => {
            const {dirty, pristine, valid, errors, invalid, value} = this.loginForm;
            this.formStatus = JSON.stringify({
                dirty,
                pristine,
                valid,
                errors,
                invalid,
                value,
            });
        });
    }

    // PENDIENTE: REPLICADO!
    // Standard function for validations. Should it be somewhere in a unique place?
    invalidInputs(formgroup: FormGroup): { [key: string]: string } {
        const messages = {};
        for (const input in formgroup.controls) {
            const key = formgroup.controls[input];
            if (key instanceof FormGroup) {
                const nestedGroupMessages = this.invalidInputs(key);
                Object.assign(messages, nestedGroupMessages);
            } else {
                if (this.validationMessages[input]) {
                    messages[input] = '';
                    if (key.errors && (key.dirty || key.touched)) {
                        Object.keys(key.errors).map((messageKey) => {
                            if (this.validationMessages[input][messageKey]) {
                                messages[input] = this.validationMessages[input][messageKey];
                            }
                        });
                    }
                }
            }
        }
        // console.log("✅invalidInputs() messages:", messages);

        return messages;
    }

    onSubmit() {
        // Invalid data, show warning message to user
        if (!this.loginForm.valid) {
            this.userMessageService.showMessage(
                'Please, check errors in highlighted form fields'
            );
        }

        // Esto parece que solo muestra los datos internos para debug y guarda en local storage los datos. cosa que mola para recuperar la operacion por cortes de cualquier tipo, aunque debería hacerse en componentes genericos
        const {formObj} = this;
        const {value} = this.loginForm;
        console.log('onSubmit()', value);
        const sth = JSON.stringify({...formObj, business: value});
        try {
            localStorage.setItem('form', sth);
        } catch {
            (e) => console.log(e);
        }
    }

    login(loginData: ILogin) {
        console.log('EL LOGIN DATA ES: ', loginData);
        if (this.loginForm.valid) {
            console.log('EL LOGIN ES VALIDO: ', loginData);
            this.userService.login(loginData).subscribe((result) => {
                console.log('login result: ', result); // PENDIENTE: Porque no puedo acceder a result.message y result.status? Solo devuelve result.token
                // this.personId = result.login.item.id;
                if (result !== null && result.token) {
                    console.log('Guardamos el token en local storage');
                    this.userAuthService.setUserToken(result.token);
                    this.userAuthService.setUserSessionInfo(result);

                    this.userMessageService.showMessage('Token generado ');
                    /*
                    if (result.refreshToken) {
                        this.refreshTokenService.setRefreshToken(result.refreshToken);
                        console.log('Refresh token guardado');
                    } else {
                        console.log('No hay refresh token');
                    }
                    */
                    // redirigimos a la página de inicio. PENDIENTE: VA cuando quiere!
                    // Con window.location.reload() va
                    // https://stackoverflow.com/questions/68753614/angular-routing-doesnt-refresh-the-page
                    // const homePage: string = "/dashboard";  //PENDIENTE Tratar como usr_Prefs
                    const homePage = '/dashboard';
                    // Pendiente mandar al usuario a su página de inicio favorita
                    // const homePage: string = "/agenda";
                    this.router.navigate([homePage]).then(() => {
                        // window.location.reload(); //para que puse esto?
                    });

                    console.log('Usuario logeado');

                } else {
                    this.userMessageService.showMessage(
                        'User is not authorized (pdte leer result.message)'
                    );
                }
                setInterval(() => (this.loading = false), 450);
            });
        } else {
            console.log('form not valid');
        }
    }

    ngOnDestroy(): void {
    }

    validationMessagesConfig() {
        return {
            userCode: {
                required: 'Enter user code',
                minLength: 'Enter valid user code',
            },
            password: {
                required: 'Enter password',
            },
        };
    }

    // Seed data for testing
    initialDataConfig() {
        if (false) {
            return {};
        } else {
            return {
                userCode: '',
                password: '',
            };
        }
    }

    loginFormConfiguration(initialData) {
        this.loginForm = this.fb.group({
            userCode: [
                {value: initialData.userCode, disabled: !this.formIsEnabled},
                [
                    Validators.required,
                    Validators.minLength(2),
                    Validators.maxLength(40),
                ],
            ],
            password: [
                {value: initialData.password, disabled: !this.formIsEnabled},
                [
                    Validators.required,
                    Validators.minLength(2),
                    Validators.maxLength(40),
                ],
            ],
            rememberMe: [],
        });
    }
}
