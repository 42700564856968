import {AbstractControl, ValidatorFn} from '@angular/forms';

export function serverErrorValidator(error: string = ''): ValidatorFn {
    const regex = /^[A-Za-z]+(?:[ A-Za-z]+)*$/;
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (error === '') {
            return null;
        }

        return  {'serverError': {value: error}};
    };
}

export function asyncErrorValidator(errorName: string = '', value: any): ValidatorFn {

    return (control: AbstractControl): { [key: string]: any } | null => {
        if (value === '') {
            return null;
        }

        return  {'identificatorInUse': {value: ''}};
    };
}