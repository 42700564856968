// https://octoperf.com/blog/2021/01/08/angular-material-multiple-themes/#theming-our-custom-component

import {Component, Inject} from '@angular/core';
import {DOCUMENT, NgIf} from '@angular/common';
import {LocalStorageService} from '../../../../services/local-storage.service';
import {MatIcon} from '@angular/material/icon';

@Component({
    selector: 'app-theme-switch',
    templateUrl: './theme-switch.component.html',
    styleUrls: ['./theme-switch.component.scss'],
    standalone: true,
    imports: [NgIf, MatIcon]
})
export class ThemeSwitchComponent {

    private static readonly DARK_THEME_CLASS = 'dark-theme';
    private static readonly DARK_THEME_LIGHT = 'light';
    private static readonly DARK_THEME_DARK = 'dark';

    public theme: string;

    constructor(@Inject(DOCUMENT) private document: Document, private localStorageService: LocalStorageService) {
        const userPreference: any = this.localStorageService.getItem('usrPrefs_theme');
        if (userPreference) {
            this.theme = userPreference;
        } else {
            this.theme = this.document.documentElement.classList.contains(ThemeSwitchComponent.DARK_THEME_CLASS) ? ThemeSwitchComponent.DARK_THEME_DARK : ThemeSwitchComponent.DARK_THEME_LIGHT;
        }

        if (this.theme == 'dark') {
            this.selectDarkTheme();
        }
        // console.log('this.theme = ' + this.theme)
    }

    public selectDarkTheme(): void {
        this.document.documentElement.classList.add(ThemeSwitchComponent.DARK_THEME_CLASS);
        this.theme = ThemeSwitchComponent.DARK_THEME_DARK;

        // this.localStorageService.setItem('usrPrefs_theme', JSON.stringify(this.theme))      //Almacena comillas!!
        this.localStorageService.setItem('usrPrefs_theme', this.theme);      // Guardamos la preferencia del usuario en local storage
    }

    public selectLightTheme(): void {
        this.document.documentElement.classList.remove(ThemeSwitchComponent.DARK_THEME_CLASS);
        this.theme = ThemeSwitchComponent.DARK_THEME_LIGHT;

        // this.localStorageService.setItem('usrPrefs_theme', JSON.stringify(this.theme))      //Guardamos la preferencia del usuario en local storage
        this.localStorageService.setItem('usrPrefs_theme', this.theme);      // Guardamos la preferencia del usuario en local storage
    }
}
