import {gql} from 'apollo-angular';


export const USER_OBJECT = gql`
    fragment UserObject on User {
        id
        userCode
        role
        password @skip(if: $skip)
        personId
        comments
        createdBy
        updatedBy
        createdAt
        updatedAt
    }
`;

export const LOGIN_OBJECT = gql`
    fragment LoginObject on Login {
        userCode
        password @skip(if: $skip)
        rememberMe
    }
`;


export const PERSON_OBJECT = gql`
    fragment PersonObject on Person {
        id
        type
        status
        title
        firstName
        lastName

        identificationType
        identification
        nationality
        birthdate
        phoneNumber
        email
        referrer

 
        comments
        createdBy
        updatedBy
        createdAt
        updatedAt
    }
`;
export const ADDRESS_OBJECT = gql`
    fragment AddressObject on Address {
        id
        address
        city
        state
        country
        postalCode
        type
        relationId
        relationType
    }
`;


// PENDIENTE: No tiene mucho sentido que el fragment sea todo el objeto. Revisar!
export const EVENT_TYPE_OBJECT = gql`
    fragment EventTypeObject on EventType {
        id
        userCompanyId
        status
        description
        color

        createdBy
        updatedBy
        createdAt
        updatedAt
    }
`;


// PENDIENTE: No tiene mucho sentido que el fragment sea todo el objeto. Revisar!
export const EVENT_OBJECT = gql`
    fragment EventObject on Event {
        id
        agendaId
        title
        type
        dateFrom
        dateTo
        comments
        recurring
        location
        recurring
        reminders
        createdBy
        updatedBy
        createdAt
        updatedAt

        stackedEventAccumLeftMargin
    }
`;


export const AGENDA_OBJECT = gql`
    fragment AgendaObject on Agenda {
        id
        title
        type
        comments

        createdBy
        updatedBy
        createdAt
        updatedAt
    }
`;

export const PRODUCT_TYPE_OBJECT = gql`
    fragment ProductTypeObject on ProductType {
        id
        userCompanyId
        status
        categoryId
        description
        code
        brand
        model
        sellingPrices {
            id
            dateFrom
            timeFrom
            sellingPrice
        }
        lots {
            code
            dateIn
            costPrice
            numOfUnits
            comments
        }
    }
`;


export const BUDGET_OBJECT = gql`
    fragment BudgetObject on Budget {
        id
        userCompanyId
        status
        description
        clientId
        client {
            firstName
            lastName
        }
        date
        description
        applyDiscountsTo
        applyTaxesTo
        applyWithholdingTaxesTo
        baseAmount
        taxPercentage
        taxAmount
        discountPercentage
        discountAmount
        discountDescription
        withholdingTaxPercentage
        withholdingtaxAmount
        totalAmount
        aditionalInfo
        comments
        budgetExpirationDate
        createdBy
        updatedBy
        createdAt
        updatedAt
    }
`;
