<textarea *ngIf="false" class="debug">
    PENDIENTE: 

    userIsLogged: {{userIsLogged}}
    formStatus:{{ formStatus }}

</textarea>

<!-- <a
  href="http://angular-material.fusetheme.com/sign-in?redirectURL=%2Fdashboards%2Fproject%3Fv12.0.0%3D"
  >Inspired in</a
>
 -->
<div class="global">
	<div class="left-pane">
		<app-user-login-form></app-user-login-form>
	</div>

	<div class="right-pane right-pane-background {{backgroundImageClass}}">
		<div>
			<h1>Welcome to our space.</h1>
			<p>14ALL helps organizations to manage tasks eficiently. Join us now and start
				enjoying your business today.</p>
		</div>
	</div>
</div>
