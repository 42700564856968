<div
  [class]="
    mainTemplateService.menuIsPinned()
      ? 'footer flex justify-between sm:pr-36 lg:pr-80'
      : 'footer flex justify-between lg:pr-32'
  "
>
  <div class="copyright">&copy; Artek. Powered by 14ALL.</div>

  <div class="main-content-style">
    <!-- DARK MODE -->
    <app-theme-switch></app-theme-switch>

    <a>
      <mat-icon class="smaller"> zoom_out </mat-icon>
    </a>

    <a>
      <mat-icon class="bigger"> zoom_in </mat-icon>
    </a>
  </div>
</div>
