import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PageHeadingSharedService {
    private eventSubject = new Subject<any>();

    // Observable to subscribe to in receiving components
    get eventObservable() {
        return this.eventSubject.asObservable();
    }

    // Method to call from emitting component
    emitEvent(data: any) {
        this.eventSubject.next(data);
    }
}
