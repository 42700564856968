<!-- FunctionsLinksParams:{{FunctionsLinksParams | json}}
<br><br>itemID:{{itemID}}
 -->
<!--  registerLinksFormatIsCollapsibleMenu: {{registerLinksFormatIsCollapsibleMenu}}
 -->

<!-- Menu desplegable de funciones -->
<div *ngIf="registerLinksFormatIsCollapsibleMenu">
	<!--3 dots button to unfold links for a register-->
	<button
			[matMenuTriggerFor]="menu"
			aria-label="Example icon-button with a menu"
			mat-icon-button
			title="Operations">
		<mat-icon>more_vert</mat-icon>
	</button>

	<!-- links in colapsible menu (3 vertical dots)-->
	<mat-menu #menu="matMenu">
		<button *ngFor="let f of FunctionsLinksParams" mat-menu-item>
			<a *ngIf="!f.click && f.link" routerLink="{{ f.link }}">
				<mat-icon>{{
						f.icon
					}}
				</mat-icon>
				<span>{{
						f.caption
					}}</span>
			</a>
			<div (click)="f.click()" *ngIf="f.click && !f.link">
				<mat-icon>{{
						f.icon
					}}
				</mat-icon>
				<span>{{
						f.caption
					}}</span>
			</div>
		</button>
	</mat-menu>
</div>

<!-- visible links in horizontal list -->
<div *ngIf="!registerLinksFormatIsCollapsibleMenu">
	<ul class="function-links">
		<li *ngFor="let f of FunctionsLinksParams">
			<a (click)="f.click()" routerLink="{{ f.link }}" title="{{ f.caption }}">
				<mat-icon>{{
						f.icon
					}}
				</mat-icon>
			</a>
		</li>
	</ul>
</div>
