import {gql} from 'apollo-angular';

export const PERSON_TYPEAHEAD_OBJECT = gql`
    fragment PersonTypeAheadObject on Person {
        id
        firstName
        lastName
        identification
        birthdate
        status
        name
        email
        typeahead
        phoneNumber
    }
`;
