import { Component, Input, OnInit } from "@angular/core";
// import { MainMenu } from './main-menu.model'; No sé si esta es la forma adecuada. Un modelo no debería tener mas que estructuras de datos y métodos, no datos.
import { LocalStorageService } from "../../../../../services/local-storage.service";
import { MenuItemComponent } from "../menu-item/menu-item.component";
import { NgFor, NgIf } from "@angular/common";

@Component({
  selector: "app-main-menu",
  templateUrl: "./main-menu.component.html",
  styleUrls: ["./main-menu.component.scss"],
  standalone: true,
  imports: [NgFor, NgIf, MenuItemComponent],
})
export class MainMenuComponent implements OnInit {
  @Input() menuFontSize = 0;
  @Input() menuIsExpanded: boolean;

  menuItems: any;
  shownSubItems: string[] = []; // Array con los ids de los items con SubItems desplegados

  // usrPrefs_menuIsPinned: string;

  constructor(private localStorageService: LocalStorageService) {
    // this.shownSubItems = []

    /*
        itemID:   Identificador del item. Se utiliza para desplegar/ocultar sus submenus
        label:    El texto del enlace
        icon:     Icono
        link:     url
        caption:  Texto de ayuda
        subItems: items del submenu del item, si existe.
        */
    this.menuItems = [
      {
        group: "operativa",
        items: [
          {
            itemID: "home",
            label: "Home",
            icon: "home",
            link: "/dashboard",
            caption: "Ir a la página inicial",
            subItems: [],
          },
          {
            itemID: "task",
            label: "Tareas",
            icon: "business_center",
            link: "/task-record/search",
            caption: "Tareas...",
            subItems: [],
          },
          {
            itemID: "agenda",
            label: "Agenda",
            icon: "calendar_today",
            link: "/agenda/month",
            caption: "Agenda, calendario, citas...",
            subItems: [
              {
                itemID: "agenda",
                label: "Búsqueda",
                icon: "search",
                link: "/agenda/month",
                caption: "Agenda, calendario, citas...",
                subItems: [],
              },
              {
                itemID: "event-add",
                label: "Evento",
                icon: "add",
                link: "/event/add",
                caption: "...",
                subItems: [],
              },
              {
                itemID: "event-type-add",
                label: "Tipo de evento",
                icon: "add",
                link: "/event-type/add",
                caption: "...",
                subItems: [],
              },
            ],
          },
          /*           {
                                itemID: "customers",
                                label: "Customers",
                                icon: "face",
                                link: "/customer/add",
                                caption: "Add customers...",
                                subItems: [],
                              }, */
          {
              itemID: 'clients',
              label: 'Clients',
              icon: 'face',
              // link: "/client/search",
              link: '/clients/search',
              caption: 'Búsquedas de Clientes, altas...',
              subItems: [
                  {
                      itemID: 'clients-search',
                      label: 'Búsqueda',
                      icon: 'search',
                      // link: "/client/search",
                      link: '/clients/search',
                      caption: '...',
                      subItems: [],
                  },
                  /*
                  {
                      itemID: 'person-show',
                      label: 'show',
                      icon: '',
                      // link: "/client/search",
                      link: '/person/{246}',
                      caption: '...',
                      subItems: [],
                  },

                  */
                {
                  itemID: 'clients-add-person',
                  label: 'Persona',
                  icon: 'add',
                  // link: "/client/add",
                  link: '/clients/add/person',
                  caption: '...',
                  subItems: [],
                },
                {
                  itemID: 'clients-add-company',
                  label: 'Empresa',
                  icon: 'add',
                  // link: "/client/add",
                  link: '/clients/add/company',
                  caption: '...',
                  subItems: [],
                },

              ],
          },
          {
            itemID: "referrers",
            label: "Referrers",
            icon: "business",
            caption: "Convenios, remitentes de clientes",
            subItems: [
              {
                itemID: "referrers-search",
                label: "Búsqueda",
                icon: "search",
                link: "/referrer/search",
                caption: "...",
                subItems: [],
              },
              {
                itemID: "referrers-add",
                label: "Nuevo",
                icon: "add",
                link: "/referrer/add",
                caption: "...",
                subItems: [],
              },
            ],
          },
          {
            itemID: "services",
            label: "Services",
            icon: "work",
            link: "/service/search",
            caption: "Servicios médicos, psiquiátricos, psicológicos",
            subItems: [
              {
                itemID: "services-search",
                label: "Búsqueda",
                icon: "search",
                link: "/service/search",
                caption: "...",
                subItems: [],
              },
              {
                itemID: "services-add",
                label: "Nuevo",
                icon: "add",
                link: "/service/add",
                caption: "...",
                subItems: [],
              },
            ],
          },
          {
            itemID: "products",
            label: "Products",
            icon: "inventory",
            link: "/product/search",
            caption: "Products",
            subItems: [
              {
                itemID: "products-search",
                label: "Búsqueda",
                icon: "search",
                link: "/product/search",
                caption: "...",
                subItems: [],
              },
              {
                itemID: "products-add",
                label: "Nuevo",
                icon: "add",
                link: "/product/add",
                caption: "...",
                subItems: [],
              },
            ],
          },
          {
            itemID: "budgets",
            label: "Budgets",
            icon: "description",
            link: "/invoicing/budget-search",
            caption: "Búsquedas de presupuestos...",
            subItems: [
              {
                itemID: "budget-search",
                label: "Búsqueda",
                icon: "",
                link: "/budget/search",
                caption: "...",
                subItems: [],
              },
              {
                itemID: "budget-add",
                label: "Nuevo",
                icon: "",
                link: "/invoicing/budget-add",
                caption: "...",
                subItems: [],
              },
            ],
          },
          {
            itemID: "invoicing",
            label: "Invoicing",
            icon: "description",
            link: "/invoice/search",
            caption: "Búsquedas de facturas...",
            subItems: [
              {
                itemID: "invoice-search",
                label: "Búsqueda",
                icon: "",
                link: "/invoice/search",
                caption: "...",
                subItems: [],
              },
              {
                itemID: "invoice-add",
                label: "Nuevo",
                icon: "",
                link: "/invoice/add",
                caption: "...",
                subItems: [],
              },
            ],
          },
          {
            itemID: "contacts",
            label: "Contacts",
            icon: "perm_contact_calendar",
            link: "/pages/contacts",
            caption: "Búsquedas de contactos...",
            subItems: [],
          },
          {
            itemID: "reports",
            label: "Reports",
            icon: "leaderboard",
            link: "/pages/reports",
            caption: "Informes de negocio...",
            subItems: [],
          },
          {
            itemID: "staff",
            label: "Staff",
            icon: "supervisor_account",
            link: "/pages/employees",
            caption: "Gestión de personal...",
            subItems: [],
          },
        ],
      },
      {
        group: "sistema",
        items: [
          {
            itemID: "users",
            label: "Users",
            icon: "lock",
            link: "/user/search",
            caption: "Gestión interna de usuarios...",
            subItems: [],
          },
          {
            itemID: "setup",
            label: "Settings",
            icon: "settings",
            link: "/pages/configuration",
            caption: "Ajustes internos de la app",
            subItems: [],
          },
          {
            itemID: "help",
            label: "Help",
            icon: "help_outline",
            link: "/help/main",
            caption: "Información de ayuda",
            subItems: [],
          },
          {
            itemID: "tests",
            label: "Tests",
            icon: "bug_report",
            link: "/test",
            caption: "Pruebas",
            subItems: [
              {
                itemID: "redux",
                label: "Redux (ngrx)",
                icon: "",
                link: "/test/redux",
                caption: "...",
                subItems: [],
              },
              {
                itemID: "referrers-search",
                label: "Search & tabs",
                icon: "",
                link: "/test/search-and-tabs",
                caption: "...",
                subItems: [],
              },
              {
                itemID: "",
                label: "table selection",
                icon: "",
                link: "/test/table-selection",
                caption: "...",
                subItems: [],
              },
              {
                itemID: "referrers-add",
                label: "Nuevo",
                icon: "",
                link: "/pages/referrers/add",
                caption: "...",
                subItems: [],
              },
              {
                itemID: "referrers-add",
                label: "Dash Zara Cooper",
                icon: "",
                link: "/dash",
                caption: "...",
                subItems: [],
              },
            ],
          },
        ],
      },
    ];
  }

  ngOnInit(): void {
    // this.usrPrefs_menuIsPinned = this.localStorageService.getItem('usrPrefs_menuIsPinned');
    // console.log("ddddddddddddddddddddddd ",this.usrPrefs_menuIsPinned)
  }

  // Se muestran los subItems que estén en el array shownSubItems
  toggleSubItems(itemID: string): void {
    // Si el bloque de SubItems está en el array shownSubItems, lo eliminamos
    if (this.shownSubItems.includes(itemID)) {
      const index = this.shownSubItems.indexOf(itemID);
      if (index !== -1) {
        this.shownSubItems.splice(index, 1);
      }
    } else {
      this.shownSubItems.push(itemID);
    }
    console.table("this.shownSubItems: " + this.shownSubItems);
  }

  // Repliega los subitems correspondientes al submenu de itemID
  hideSubItems(itemID: string): boolean {
    // Si el bloque de SubItems está en el array shownSubItems, lo eliminamos
    if (this.shownSubItems.includes(itemID)) {
      const index = this.shownSubItems.indexOf(itemID);
      if (index !== -1) {
        this.shownSubItems.splice(index, 1);
        return true;
      }
    }
    return false;
  }

  foldMenu(): void {
    this.shownSubItems.length = 0;
  }

  // Despliega todos los submenus
  unfoldMenu(): void {
    for (let myGroup in this.menuItems) {
      for (let myItem in this.menuItems[myGroup].items) {
        let item: string = this.menuItems[myGroup].items[myItem].itemID;
        if (!this.shownSubItems.includes(item)) {
          this.shownSubItems.push(item);
          // console.log('despliego lvl 1: ' + item)
        }
        // console.log('vvvv tabla de subItems de '+item+' vvvvvvvvvvvvvvvvvv')
        // console.log(this.menuItems[myGroup].items[myItem].subItems)
        // console.log('^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^')

        for (let mySubItem in this.menuItems[myGroup].items[myItem].subItems) {
          // console.log('mySubItem[]======'+this.menuItems[myGroup].items[myItem].subItems[mySubItem].itemID)
          let subItem: string =
            this.menuItems[myGroup].items[myItem].subItems[mySubItem].itemID;
          this.shownSubItems.push(subItem);
          // console.log('despliego lvl 2: ' + subItem)
        }
      }
    }
    //   console.log('unfoldMenu():::::')
    // console.table(this.shownSubItems)
  }
}
