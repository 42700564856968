<div class=" position-relative h-24 w-full bg-[var(--mat-app-background-color)] z-[999999] " style="max-width: -moz-available">
    <div *ngIf="true" style="max-width: inherit" class="bg-[var(--mat-app-background-color)] header fixed w-full mb-40">

        <!-- MOSTRAR/OCULTAR MENU PRINCIPAL -->
        <button (click)="mainMenuToggle()" *ngIf="smallScreen()" mat-icon-button>
            <mat-icon *ngIf="!sidenavOpened">
                menu
            </mat-icon>
            <mat-icon *ngIf="sidenavOpened">
                close
            </mat-icon>
        </button>


        <div class="user-options">
            <ng-container *ngIf="showExpanded; else showMenu">
                <button (click)="goBack()"  mat-icon-button>
                    <mat-icon >
                        arrow_back
                    </mat-icon>
                </button>

                <button (click)="openFullscreen()" mat-icon-button>
                    <mat-icon *ngIf="true">
                        fullscreen
                    </mat-icon>
                    <mat-icon *ngIf="false">
                        fullscreen_exit
                    </mat-icon>
                </button>

                <!-- FAVORITOS -->
                <button (click)="pendingWork('favoritos!')" mat-icon-button>
                    <mat-icon>
                        star_rate
                    </mat-icon>
                </button>

                <!-- BUSQUEDA -->
                <button (click)="pendingWork('Omnisearch!')" mat-icon-button>
                    <mat-icon>
                        search
                    </mat-icon>
                </button>

                <!-- HELP -->
                <button (click)="pendingWork('Ayuda!')" mat-icon-button>
                    <mat-icon>
                        help_outline
                    </mat-icon>
                </button>

                <button (click)="toggleTemplate()" mat-icon-button>
                    <mat-icon>
                        unfold_less
                    </mat-icon>
                </button>
            </ng-container>

            <ng-template #showMenu>
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button (click)="goBack()" mat-menu-item [matTooltip]="'Go back'">
                        <mat-icon>
                            arrow_back
                        </mat-icon>
                        <span>Go back</span>
                    </button>

                    <button (click)="openFullscreen()" mat-menu-item [matTooltip]="'Toggle fullscreen'">
                        <mat-icon *ngIf="true">
                            fullscreen
                        </mat-icon>
                        <mat-icon *ngIf="false">
                            fullscreen_exit
                        </mat-icon>
                        <span>Toggle fullscreen</span>
                    </button>

                    <!-- FAVORITOS -->
                    <button (click)="pendingWork('favoritos!')" mat-menu-item [matTooltip]="'Add page to favourites'">
                        <mat-icon>
                            star_rate
                        </mat-icon>
                        <span>Add to favorites</span>
                    </button>

                    <!-- BUSQUEDA -->
                    <button (click)="pendingWork('Omnisearch!')" mat-menu-item [matTooltip]="'Global search'">
                        <mat-icon>
                            search
                        </mat-icon>
                        <span>Search</span>
                    </button>

                    <!-- HELP -->
                    <button (click)="pendingWork('Ayuda!')" mat-menu-item [matTooltip]="'Help'">
                        <mat-icon>
                            help_outline
                        </mat-icon>
                        <span>Help</span>
                    </button>
                    <button *ngIf="screenSize === ScreenSizeEnum.isDesktop" (click)="toggleTemplate()"  mat-menu-item [matTooltip]="'Toggle list'">
                        <mat-icon>
                            unfold_more
                        </mat-icon>
                        <span>Expand</span>
                    </button>
                </mat-menu>
            </ng-template>

            <button (click)="pendingWork('User notifications!')" mat-icon-button>
                <mat-icon matBadge="3" matBadgeColor="warn" matBadgeSize="small" aria-hidden="false">
                    notifications_none
                </mat-icon>
            </button>

            <div *ngIf="!smallScreen()" class="user-info">
                <div class="company">{{
                        userCompanyName
                    }}
                </div>
                <div class="name">{{
                        userFullName
                    }}
                </div>
                <div class="role">{{
                        userRoleDescription
                    }}
                </div>
            </div>

            <app-user-menu [userCompanyId]="userCompanyId" [userId]="userId"></app-user-menu>

        </div>
    </div>
</div>
