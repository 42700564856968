import {Routes} from '@angular/router';
import {UserSearchComponent} from './admin/user/user-search/user-search.component';
import {personResolver} from './admin/person/person.resolver';
import {personSearchResolver} from './admin/person/person-search/person-search.resolver';
import {personCompanyAiResolver} from "./admin/shared/person-additional-info/person-company-ai.resolver";

export const routes: Routes = [
    /** LOGIN **/
    {
        path: 'login',
        loadComponent: () => import('./admin/user/user-login/user-login.component')
            .then((c) => c.UserLoginComponent)
    },
    /** DASHBOARD **/
    {
        path: 'dashboard',
        loadComponent: () =>
            import('./admin/dashboard/dashboard/dashboard.component')
                .then((m) => m.DashboardComponent),
    },
    /** AGENDA: TODO revisar, parece que tiene algún fallo  **/
    {
        path: 'agenda/month',
        loadComponent: () => import('./admin/agenda/agenda/agenda.component')
            .then((c) => c.AgendaComponent)
    },
    {
        path: 'agenda/week',
        loadComponent: () => import('./admin/agenda/agenda/agenda.component')
            .then((c) => c.AgendaComponent)
    },
    {
        path: 'agenda/days/:noOfDisplayedDays',
        loadComponent: () => import('./admin/agenda/agenda/agenda.component')
            .then((c) => c.AgendaComponent)
    },
    {
        path: 'agenda/list',
        loadComponent: () => import('./admin/agenda/agenda/agenda.component')
            .then((c) => c.AgendaComponent)
    },
    /** CLIENTS **/
    {
        path: 'client/:id',
        loadComponent: () => import('./admin/client/client-add/client-add.component')
            .then((c) => c.ClientAddComponent),
        resolve: {personCompanyAdditionalInformation: personCompanyAiResolver}

    },
    {
        path: 'clients/add/:clientType',
        loadComponent: () => import('./admin/client/client-add/client-add.component')
            .then((c) => c.ClientAddComponent),
        resolve: {clients: personSearchResolver}
    },
    {
        path: 'clients/search',
        loadComponent: () => import('./admin/client/client-search/client-search.component') //import('./admin/person/person-search/person-search.component')
            .then((c) => c.ClientSearchComponent),
        // resolve: {clients: personSearchResolver}
    },

    /*
    {
        path: 'person/:id',
        loadComponent: () => import('./admin/person/person-show/person-show.component')
            .then((c) => c.PersonShowComponent),
        resolve: {person: personResolver}
    },
    */
    {
        path: 'referrer/add',
        loadComponent: () => import('./admin/referrer/referrer-mutate/referrer-mutate.component')
            .then((c) => c.ReferrerMutateComponent)
    },
    {
        path: 'referrer/search',
        loadComponent: () => import('./admin/referrer/referrer-search/referrer-search.component')
            .then((c) => c.ReferrerSearchComponent)
    },
    {
        path: 'referrer/:id',
        loadComponent: () => import('./admin/referrer/referrer-mutate/referrer-mutate.component')
            .then((c) => c.ReferrerMutateComponent),
        resolve: {personCompanyAdditionalInformation: personCompanyAiResolver}

    },

    {
        path: 'profile/:profileType/:id',
        loadComponent: () => import('./admin/profile/profile.component')
            .then((c) => c.ProfileComponent)
        //resolve: {person: personResolver}
    },
    /** EJEMPLO **/
    {
        path: 'ejemplo/add',
        loadComponent: () => import('./admin/ejemplo/ejemplo-add/ejemplo-add.component')
            .then((c) => c.EjemploAddComponent)
    },
    /**
     {
     path: 'client/update/:id',
     loadComponent: () => import('./admin/person/person-form/person-form.component.ts.old')
     .then((c) => c.PersonFormComponent)
     },
     {
     path: 'client/:id',
     loadComponent: () => import('./admin/person/person-form/person-form.component.ts.old')
     .then((c) => c.PersonFormComponent)
     },
     **/
    /**
     * PRODUCTS
     */
    {
        path: 'product/add',
        loadComponent: () => import('./admin/product/product-type-add/product-type-add.component')
            .then((c) => c.ProductTypeAddComponent),
    },
    {
        path: 'product/search',
        loadComponent: () => import('./admin/product/product-type-search/product-type-search.component')
            .then((c) => c.ProductTypeSearchComponent)
    },
    {
        path: 'product/:productId',
        loadComponent: () => import('./admin/product/product-type-new/product-type-new.component')
            .then((c) => c.ProductTypeNewComponent)
    },

    /**
     * SERVICES
     */
    {
        path: 'service/add',
        loadComponent: () => import('./admin/service/service-type-add/service-type-add.component')
            .then((c) => c.ServiceTypeAddComponent)
    },
    {
        path: 'service/search',
        loadComponent: () => import('./admin/service/service-type-search/service-type-search.component')
            .then((c) => c.ServiceTypeSearchComponent)
    },
    {
        path: 'service/:serviceId',
        loadComponent: () => import('./admin/service/service-type-new/service-type-new.component')
            .then((c) => c.ServiceTypeNewComponent)
    },

    /**
     * EVENTS
     */
    {
        path: 'event/add',
        loadComponent: () => import('./admin/event/event-add-page/event-add-page.component')
            .then((c) => c.EventAddPageComponent),
    },
    {
        path: 'event-type/add',
        loadComponent: () => import('./admin/event/event-type-add-page/event-type-add-page.component')
            .then((c) => c.EventTypeAddPageComponent)
    },
    // {
    //     path: 'event-category/search',
    //     loadComponent: () => import('./admin/product/product-type-search/product-type-search.component')
    //         .then((c) => c.ProductTypeSearchComponent)
    // },
    // {
    //     path: 'event-category/:eventCategoryId',
    //     loadComponent: () => import('./admin/product/product-type-new/product-type-new.component')
    //         .then((c) => c.ProductTypeNewComponent)
    // },

    //{path: 'client/add', component: ClientAddComponent},

    ////{
    //  path: "client/add",
    //  loadChildren: () =>
    //    import("./admin/person/client-add/client-add.module").then(
    //      (m) => m.ClientAddModule
    //    ),
    //},
    //{ path: 'client/search', component: ClientSearchComponent },
    //{path: 'client/search', component: PersonSearchComponent},


    {
        path: 'user/add',
        loadComponent: () =>
            import('./admin/user/user-add/user-add.component').then(
                (m) => m.UserAddComponent
            ),
    },
    {path: 'user/search', component: UserSearchComponent},
    //{path: 'user/:id', component: UserDetailsComponent},
    /*     {
          path: 'user/:id',
          loadChildren: () => import('./admin/user/user-detail/user-detail.module').then((m) => m.UserDetailModule)
      },
   */
    /**
     {
     path: 'company/add',
     loadComponent: () =>
     import('./admin/company/company-add/company-add.component').then(
     (m) => m.CompanyAddComponent
     ),
     },

     //Invoicing
     //{
     //    path: 'invoicing',
     //    loadComponent: () =>
     //        import('./admin/invoicing/invoicing').then(
     //            (m) => m.InvoicingRoutingModule
     //        ),
     //},
     //
     //Tests
     //    { path: 'test/search-and-tabs', component: SearchAndTabsComponent },
     {path: 'test/table-http', component: TableHttpComponent},
     {path: 'test/table-selection', component: TableSelectionExampleComponent},
     {path: 'test/table-graphql', component: TableGraphqlComponent},
     {
     path: 'test/client-search-local-server',
     component: ClientSearchLocalServerComponent,
     },
     {
     path: 'test/client-search-graphql-server',
     component: ClientSearchGraphqlServerComponent,
     },
     {
     path: 'test/client-search-ngx-pagination',
     component: ClientSearchNgxPaginationComponent,
     },

     {path: 'test', component: TestComponent},
     {path: 'test/redux', component: ReduxComponent},
     {path: 'test/magreo', component: MagreoComponent},
     {path: 'test/typeahead', component: TypeaheadComponent},
     {path: 'test/typeahead2', component: Typeahead2Component},
     {path: 'test/typeahead3', component: Typeahead3Component},
     */
    //Esto viene de angular-material-course
    /*     {
          path: "",
          component: HomeComponent
      },
      {
          path: "about",
          component: AboutComponent
      },
      */
    {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full'
    },
    {
        path: '**', //ruta por defecto
        redirectTo: '/',
    },
];
