import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderCellDef,
    MatHeaderRow,
    MatHeaderRowDef,
    MatNoDataRow,
    MatRow,
    MatRowDef,
    MatTable,
    MatTableDataSource
} from '@angular/material/table';

import {IUser} from '../../../@interfaces/user.interface';
import {UserService} from '../../user/user.service';

import {IFunctionsLinksParams} from '../../../@interfaces/functionsLinksParams.interface';

import {USER_ROLE} from '../../../general/classifications';
import {PageHeadingComponent} from '../../shared/page-layout/page-heading/page-heading.component';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {MatFabButton} from '@angular/material/button';
import {RouterLink} from '@angular/router';
import {MatIcon} from '@angular/material/icon';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {
    DatatableRegisterLinksTogglerComponent
} from '../../shared/datatable-register-links-toggler/datatable-register-links-toggler.component';
import {DatePipe, NgClass, NgIf, NgOptimizedImage} from '@angular/common';
import {DatatableRegisterLinksComponent} from '../../shared/datatable-register-links/datatable-register-links.component';

@Component({
    selector: 'user-search',
    templateUrl: './user-search.component.html',
    styleUrls: ['./user-search.component.scss'],
    imports: [
        PageHeadingComponent,
        MatProgressSpinner,
        MatFabButton,
        RouterLink,
        MatIcon,
        MatFormField,
        MatInput,
        MatTable,
        MatSort,
        MatColumnDef,
        MatHeaderCellDef,
        MatHeaderCell,
        DatatableRegisterLinksTogglerComponent,
        MatCellDef,
        NgClass,
        MatCell,
        DatatableRegisterLinksComponent,
        NgOptimizedImage,
        DatePipe,
        MatHeaderRowDef,
        MatHeaderRow,
        MatRowDef,
        MatPaginator,
        MatPaginator,
        MatRow,
        NgIf,
        NgIf,
        MatNoDataRow,
        MatLabel
    ],
    standalone: true
})


export class UserSearchComponent implements AfterViewInit {

    usersList: Array<IUser> = [];   // array de usuarios (interface IUser) recuperado de la respuesta del api

    heading = 'Users';              // Teniendo el header definido en el componente como una propiedad, podemos cambiarlo dinamicamente. Por ejemplo, si en el form de busqueda se cambia un filtro, podemos cambiar el titulo. Ej: "Cancelled Users"
    loading = false;

    registerLinksFormatIsCollapsibleMenu = true;      // PENDIENTE: Guardar en local storage la preferencia del usuario

    userRole = USER_ROLE;

    // Propiedad para pasar la estructura de los links para cada usuario mostrado en la busqueda
    FunctionsLinksParams: Array<IFunctionsLinksParams> = [
        {'icon': 'search', 'caption': 'Browse', 'link': '/user/{}'},
        {'icon': 'edit', 'caption': 'Modify', 'link': '/user/update/{}'},
        {'icon': 'delete', 'caption': 'Delete', 'link': '/user/delete/{}'},
        {'icon': 'email', 'caption': 'Email', 'link': '/user/email/{}'},
        {'icon': 'call', 'caption': 'Call', 'link': '/user/call/{}'},
    ];

    itemID = '';                                            // Contendrá el id del usuario, para pasársela (al menos) al componente "datatable-register-links"

    displayedColumns: string[] = ['ops', 'id', 'image', 'userCode', 'name', 'role', 'comments', 'createdAt'];

    dataSource = new MatTableDataSource<IUser>();            // Utilizamos el api de Material Datatable para presentar los resultados de la busqueda

    clickedRows = new Set<IUser>();                          // Almacenamos las filas que el usuario ha clicado

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private users: UserService) {
    }

    ngOnInit(): void {
        this.loading = true;

        this.users.list().subscribe((result) => {                     // Llamada al servicio que devuelve los usuarios, que hemos inyectado en el constructor
            this.usersList = result;                                    // Cargamos la respuesta del api en la propiedad this.usersList, para que se pueda tratar desde la vista
            console.log('this.usersList=', this.usersList);
            this.dataSource = new MatTableDataSource(this.usersList);   // Assign the data to the data source for the table to render
            this.dataSource.paginator = this.paginator;                 // Inicializamos los parámetros de Material para la paginación
            this.dataSource.sort = this.sort;                           // Inicializamos los parámetros de Material para la ordenacion
            setInterval(() => this.loading = false, 450);
            // console.log('◼ 1 ngOnInit()', '\nthis.usersList', this.usersList, '\nthis.dataSource', this.dataSource);  //El problema es que esto se ejecuta despues de inicializazr MAtTableDataSource
        });

        // console.log('◼ 2 ngOnInit()', '\nthis.usersList', this.usersList, '\nthis.dataSource', this.dataSource);  //El problema es que esto se ejecuta despues de inicializazr MAtTableDataSource
    }

    ngAfterViewInit() {
        // En teoría habría que poner en ngAfterViewInit() estas inicializaciones, pero si lo hago así (en vez de despues del subscribe), se ejecutan antes de que el subscribe acabe, con lo que dataSource no está cargado y no se pinta nada en la tabla
        //    this.dataSource.paginator = this.paginator;
        //    this.dataSource.sort = this.sort;
    }

    applyFilter(event: Event) {                                       // Filtrado de resultados de Material
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    // Cambia el formato de presentación de los enlaces a las funciones de cada registro (usuario) mostrado
    // PENDIENTE! y esto hay que repetirlo en todos los componentes de busqueda?? noooo mola
    public toggleRegisterLinksFormat(): void {
        this.registerLinksFormatIsCollapsibleMenu = !this.registerLinksFormatIsCollapsibleMenu;
    }

    // Esto es muy general!!
    // Actualización cuando se clica en una fila
    public clickedRow(row: IUser): void {
        (this.clickedRows.has(row) ? this.clickedRows.delete(row) : this.clickedRows.add(row));
        console.log('this.clickedRows', this.clickedRows);
    }

}
