import {LOCALE_ID, NgModule} from '@angular/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {CommonModule, registerLocaleData} from '@angular/common';
import {Apollo, ApolloModule} from 'apollo-angular';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {FormGeneratorNewService} from './admin/shared/form/form-generator-new.service';
import {CookieModule, CookieOptionsProvider, CookieService} from 'ngx-cookie';
import {GraphQLModule} from './@graphql/modules/graphql.module';
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';


export function HttpLoaderFactory(http: HttpClient) {
    // Customize the path as needed; this is just a starting point
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const localeId = localStorage.getItem('usrPrefs_language') === 'es' ? 'es' : 'en';
const locale = localeId === 'es' ? localeEs : localeEn;
// registerLocaleData(locale, localeId);
registerLocaleData(localeEs, 'es');

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        HttpClientModule,
        ApolloModule,
        GraphQLModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient] // Dependency on HttpClient
            }
        }),
        CookieModule
    ],
    providers: [ApolloModule,
        Apollo,
        HttpClient,
        TranslateModule,
        FormGeneratorNewService,
        CookieService,
        CookieOptionsProvider,
        CookieModule,
        {provide: LOCALE_ID, useValue: 'es'}],
    exports: [HttpClientModule,
        ApolloModule,
        TranslateModule,
    ],
    bootstrap: []
})
export class AppModule {
}
