import {Component, Input, OnInit} from '@angular/core';
import {NgIf} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {InViewDirective} from '../../form/in-view.directive';
import {PageHeadingSharedService} from '../../service/page-heading-shared.service';
import {ScreenSizeService} from '../../../../services/screen-size.service';

@Component({
    selector: 'app-page-heading',
    templateUrl: './page-heading.component.html',
    styleUrls: ['./page-heading.component.scss'],
    standalone: true,
    imports: [NgIf,
        TranslateModule,
        InViewDirective]
})
export class PageHeadingComponent implements OnInit {

    @Input() heading: string;      // El texto a pintar. Ej: "Alta de cliente"
    @Input() prefix: string;      // El texto a pintar. Ej: (?)
    @Input() suffix: string;      // El texto a pintar. Ej: "Pepe Perez"
    @Input() subheading: string;      // El texto a pintar. Ej: "Alta de cliente"
    headingVisibility: boolean;
    protected readonly document = document;


    constructor(private pageHeadingSharedService: PageHeadingSharedService, protected screenSizeService: ScreenSizeService) {
    }

    ngOnInit(): void {
    }

    handleInView(event: { id: string, isInView: boolean }): void {
        this.headingVisibility = event.isInView;
        this.pageHeadingSharedService.emitEvent(event.isInView);
        console.log('handleInView', this.headingVisibility);
    }

}
