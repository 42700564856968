import { Injectable } from "@angular/core";
import { LocalStorageService } from "../../../services/local-storage.service";
import { Router } from "@angular/router";
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: "root",
})
export class UserAuthService {
  // Esto estaría mejor en un interface userSessionInfo
  decodedUserToken: any = {};
  userId = 0;
  userCompanyId = 0;
  userFullName = "";
  userCompanyName = "";
  userRole = "";
  // El nombre con el que se almacena el token en local storage
  private userToken = "userToken";

  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
  ) {
    // this.getUserSessionInfo()
  }

  userIsLogged(debug: string): boolean {
    const userToken: string = this.localStorageService.getItem(this.userToken);
    // console.log(debug + " Verificando token... this.userIsLogged = ", this.userIsLogged, userToken);

    if (userToken !== null) {
      return true;
    } else {
      return false;
    }
  }

  setUserToken(token: string): void {
    this.localStorageService.setItem(this.userToken, token);
  }

  // Almacenamos en localstorage los datos del usuario y la empresa
  setUserSessionInfo(sessionInfo) {
    console.log("SESSIONINFO", sessionInfo);
    this.localStorageService.setItem(
      "usrSess_fullName",
      sessionInfo.user.personAssociatedWithUser.firstName,
    );
    this.localStorageService.setItem(
      "usrSess_companyTradename",
      sessionInfo.user.userCompanyAssociatedWithUser.tradename,
    );
    this.localStorageService.setItem(
      "usrSess_companyLegalName",
      sessionInfo.user.userCompanyAssociatedWithUser.legalName,
    );
  }

  deleteUserSessionInfo(): void {
    console.log("deleting userToken");
    this.localStorageService.removeItem(this.userToken);

    this.localStorageService.removeItem("usrSess_fullName");
    this.localStorageService.removeItem("usrSess_companyTradename");
    this.localStorageService.removeItem("usrSess_companyLegalName");
  }

  // mejor checkUserAuthorized() ?
  processRefreshedToken(refreshedToken) {
    // console.log("refreshedToken", refreshedToken);
    if (refreshedToken == null) {
      console.log("Token invalid. Redirecting to login...");
      // PORQUE ME ECHA CONSTANTEMENTE?
      //     this.router.navigate(["/login"]).then(() => {
      //       window.location.reload();
      //     });
    } else {
      // console.log("Token Ok. Refreshing...");
      this.setUserToken(refreshedToken);
    }
  }

  // https://stackoverflow.com/questions/48075688/how-to-decode-the-jwt-encoded-token-payload-on-client-side-in-angular
  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return "";
    }
  }

  getUserSessionInfo() {
    this.userFullName = this.localStorageService.getItem("usrSess_fullName");
    this.userCompanyName = this.localStorageService.getItem(
      "usrSess_companyTradename",
    );

    this.decodedUserToken = this.getDecodedAccessToken(
      this.localStorageService.getItem("userToken"),
    );

    if (this.decodedUserToken !== "" && this.decodedUserToken !== undefined) {
      this.userId = this.decodedUserToken.user?.id;
      this.userCompanyId = this.decodedUserToken.user?.userCompanyId;

      this.userRole = this.decodedUserToken.user?.role;
    }
  }
}
