import {AbstractControl, ValidatorFn} from '@angular/forms';

export function cifValidator(): ValidatorFn {
    const CIF_REGEXP = /^[ABCDEFGHJNPQRSUVW]\d{7}[0-9A-J]$/;

    return (control: AbstractControl): { [key: string]: any } | null => {
        if (!control.value) {
            console.log('cifValidator', "OK SALE EN 1");
            return null;
        }

        const cif = control.value.trim().toUpperCase();
        if (!CIF_REGEXP.test(cif)) {
            console.log('cifValidator', "ERROR SALE EN 2");
            return { 'cif': { value: control.value } };
        }

        const letters = 'JABCDEFGHI';
        const controlLetter = cif[0];
        const digits = cif.slice(1, -1).split('').map(Number);
        const controlDigit = cif.slice(-1);

        let evenSum = 0;
        let oddSum = 0;

        for (let i = 0; i < digits.length; i++) {
            if (i % 2 === 0) {
                let product = digits[i] * 2;
                if (product > 9) {
                    product -= 9;
                }
                oddSum += product;
            } else {
                evenSum += digits[i];
            }
        }

        const totalSum = evenSum + oddSum;
        const checksum = (10 - (totalSum % 10)) % 10;

        if (controlLetter.match(/[KLM]/)) {
            if (controlDigit !== letters[checksum]) {
                console.log('cifValidator', "ERROR SALE EN 3");
                return { 'cif': { value: control.value } };
            }
        } else if (controlLetter.match(/[ABCDEFGHJNPQRSUVW]/)) {
            if (controlDigit != checksum && controlDigit !== letters[checksum]) {
                console.log('cifValidator', "ERROR SALE EN 4");
                return { 'cif': { value: control.value } };
            }
        } else {
            console.log('cifValidator', "ERROR SALE EN 5");
            return { 'cif': { value: control.value } };
        }
        console.log('cifValidator', "OK SALE EN 6");
        return null;
    };
}