import {AbstractControl, ValidatorFn} from "@angular/forms";

export function websiteValidator(): ValidatorFn {
    const URL_REGEXP = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/.*)?$/;

    return (control: AbstractControl): { [key: string]: any } | null => {
        if (!control.value) {
            return null;
        }

        const url = control.value.trim();
        if (!URL_REGEXP.test(url)) {
            return { 'website': { value: control.value } };
        }

        return null;
    };
}
