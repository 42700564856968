import {Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

@Directive({
    selector: '[appInView]',
    standalone: true
})
export class InViewDirective implements OnInit, OnDestroy {
    @Input() appIsInViewId: string; // Unique identifier for each element
    @Output() isInView: EventEmitter<{ id: string, isInView: boolean }> = new EventEmitter();

    private observer: IntersectionObserver;

    constructor(private el: ElementRef) {
    }

    ngOnInit(): void {
        const options: IntersectionObserverInit = {
            root: null, // viewport

            threshold: [0]// Trigger when the first pixel appears or disappears
        };

        this.observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                // Emit true immediately when part of the element is visible
                if (entry.isIntersecting) {

                    this.isInView.emit({id: this.appIsInViewId, isInView: true});

                } else {
                    // Check if the element has been scrolled above the viewport
                    const isAboveViewport = entry.boundingClientRect.bottom < 0;
                    if (isAboveViewport) {
                        // Element is not visible and is above the viewport
                        this.isInView.emit({id: this.appIsInViewId, isInView: false});
                    }
                    // Note: This does not emit for elements below the viewport
                }
            });
        }, options);

        this.observer.observe(this.el.nativeElement);
        /**
         const options: IntersectionObserverInit = {
         root: null,
         threshold: 0.5
         };

         this.observer = new IntersectionObserver((entries) => {
         entries.forEach((entry) => {
         this.isInView.emit({id: this.appIsInViewId, isInView: entry.isIntersecting});
         });
         }, options);

         this.observer.observe(this.el.nativeElement);
         **/
    }

    ngOnDestroy(): void {
        this.observer.disconnect();
    }
}
