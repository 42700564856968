import {ResolveFn} from '@angular/router';
import {inject} from '@angular/core';
import {PersonService} from '../person.service';
import {IRunSearch} from '../../../@interfaces/run-search.interface';
import {IPerson, IPersonSearchInput} from '../../../@interfaces/person.interface';
import {Observable} from 'rxjs';
import {IListResult} from '../../../@interfaces/list-result';


export const personSearchResolver: ResolveFn<Observable<IListResult<IPerson>>> = (route, state) => {
    const iRunSearch: IRunSearch<IPersonSearchInput> = {
        searchParams: {},
        orderBy: [{'field': 'id', 'direction': 'desc'}],
        offset: route.params.offset ?? 0,
        limit: route.params.limit ?? 10
    };
    return inject(PersonService).list(iRunSearch);
};
