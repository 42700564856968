import { Component, inject, OnInit } from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import { ThemeSwitchComponent } from "../theme-switch/theme-switch.component";
import { LocalStorageService } from "../../../../services/local-storage.service";
import { MainTemplateService } from "../main-template/services/main-template.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
  standalone: true,
  imports: [ThemeSwitchComponent, MatIcon],
})
export class FooterComponent {
  mainTemplateService = inject(MainTemplateService);
}
