import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {from, Observable} from 'rxjs';
import {groupBy, map, mergeMap, reduce} from 'rxjs/operators';
import {FieldInterface} from './form-config/field-interface';
import {FormConfigInterface} from './form-config/form-config-interface';
import {StringDictionary} from '../../../general/classifications';
import {patternNameValidator} from './validators/pattern-name-validator';
import {validOptionValidator} from './validators/valid-option-validator';
import {dateBeforeTodayValidator} from './validators/date-before-today-validator';
import {dateFormatValidator} from './validators/date-format-validator';
import {FieldGroupIdentificatorInterface, FieldIdentificationType} from './form-generator.service';
import {Injectable} from '@angular/core';

interface Section {
    section: string;
    sectionFields: FieldInterface[];
    arrays?: ArrayField[];
}

interface ArrayField {
    array: string;
    arrayFields: FieldInterface[];
}

const validatorMapping = {
    'required': () => Validators.required,
    'pattern': (val) => val.translationKey === 'pattern_name' ? patternNameValidator() : Validators.pattern(val.translationKey),
    'minlength': (val) => Validators.minLength(val.params?.requiredLength),
    'maxlength': (val) => Validators.maxLength(val.params?.requiredLength),
    'email': () => Validators.email,
    'invalidOption': (val, options) => validOptionValidator(options),
    'dateBeforeToday': () => dateBeforeTodayValidator(),
    'dateFormat': () => dateFormatValidator(),
};

@Injectable({
    providedIn: 'root'
})

export class FormGeneratorNewService {
    formIsEnabled = true;
    public isOriginal = false;

    constructor(private fb: FormBuilder) {
        this.group = this.fb.group({}) as FormGroup;
    }

    private _config: FormConfigInterface;

    get config(): FormConfigInterface {
        return this._config;
    }

    set config(config: FormConfigInterface) {
        this._config = config;
    }

    private _group: FormGroup;

    get group(): FormGroup {
        return this._group;
    }

    set group(value: FormGroup) {
        this._group = value;
    }

    async generate(config: FormConfigInterface, initData: any, isEnabled: boolean): Promise<void> {
        this.config = config;
        this.formIsEnabled = isEnabled;

        await this.sectionsConfig().then((sections) => {
            for (const section of sections) {
                section.subscribe((section) => {
                    console.log('LA SECTION ES: ', section);
                    this.createFormGroupNew(section, initData);
                });

            }


        });

    }

    async sectionsConfig(): Promise<Observable<Section>[]> {
        const lista: Observable<Section>[] = [];
        for (const field of Object.values(this.config.fields)) {
            const sectionField = from([field]).pipe(
                map((field: FieldInterface) => {
                    if (!field.section) {
                        field.section = 'default';
                    }
                    return field;
                }),
                groupBy(p => p.section),
                mergeMap((group$) =>
                    group$.pipe(reduce((acc, cur) => [...acc,
                        cur], []))
                ),
                map((arr) => {
                    const section: Section = {section: arr[0].section, sectionFields: arr};
                    return section;
                })
            );
            const array = this.arraysConfig(sectionField).pipe(
                map((field: Section) => {
                    if (!field.section) {
                        field.section = 'default';
                    }
                    return field;
                }),
                groupBy((p: Section) => p.section),
                mergeMap((group$) =>
                    group$.pipe(reduce((acc, cur) => [...acc,
                        cur], []))
                ),
                map((arr: FieldInterface[]) => {
                        return {section: arr[0].section, sectionFields: arr};
                    }
                ));
            lista.push(array);
        }
        return (lista);


// After
    }


    arraysConfig(sectionsConfig: Observable<Section>): Observable<Section> {
        return sectionsConfig.pipe(
            map((section: Section) => {
                const arrayFields = section.sectionFields.filter((field) => field.array);
                const arrays = arrayFields.reduce((acc, field) => {
                    if (!acc[field.array]) {
                        acc[field.array] = [];
                    }
                    acc[field.array].push(field);
                    return acc;
                }, {});
                return {
                    ...section,
                    sectionFields: section.sectionFields.filter(field => !field.array),
                    arrays: Object.values(arrays).map((arrayFields: FieldInterface[]) => {
                        const arrayName: string = arrayFields[0].array;
                        return {array: arrayName, arrayFields: arrayFields};
                    })
                };
            })
        );
    }

    public createFormGroupNew(section: Section, initData: any): Observable<FormGroup> {
        this.group.addControl(section.section, this.createGroup(section.sectionFields, initData));

        for (const array of section.arrays) {

            const gp = this.group.get(section.section) as FormGroup;
            gp.addControl(array.array, new FormArray([this.createGroup(array.arrayFields, initData)]));
        }

        return this.group.get(section.section).valueChanges.pipe(
            map(() => this.group.get(section.section) as FormGroup)
        );
    }

    public createFormGroup(formConfig: FormConfigInterface, initialData: any, fieldGroupIdentification?: FieldGroupIdentificatorInterface): Observable<FormGroup> {
        return from(new Promise<FormGroup>((resolve) => {
            const formGroup = this.fb.group({}) as FormGroup<any>;
            const filterFields = (formFieldConfig: FieldInterface) => {
                if (fieldGroupIdentification) {
                    const {identificationBy, groupName} = fieldGroupIdentification;
                    if (identificationBy in formFieldConfig && formFieldConfig[identificationBy] === groupName) {
                        return !(identificationBy === FieldIdentificationType.section && FieldIdentificationType.array in formFieldConfig);
                    }
                }
                return !fieldGroupIdentification && !(FieldIdentificationType.section in formFieldConfig) && !(FieldIdentificationType.array in formFieldConfig);
            };

            const formGroupFieldsConfig = Object.values(formConfig.fields).filter(filterFields);

            formGroupFieldsConfig.forEach(field => {
                formGroup.addControl(field.name, this.createFieldControl(field, initialData[field.name] ?? ''));
            });

            resolve(formGroup);
        }));
    }

    public addSection(sectionName: string, formConfig: FormConfigInterface, initialData: any = {}): void {
        if (!sectionName || !formConfig) {
            throw new Error('Section name and form configuration are required');
        }
        if (this.group.get(sectionName)) {
            return;
        }
        const identifiedBy: FieldGroupIdentificatorInterface = {
            identificationBy: FieldIdentificationType.section,
            groupName: sectionName
        };


        this.group.addControl(sectionName, this.createFormGroup(formConfig, initialData, identifiedBy));

    }

    public removeSection(sectionName: string): void {
        if (this.group.get(sectionName)) {
            this.group.removeControl(sectionName);
        }
    }

    private createFieldControl(field: FieldInterface, fieldData: any): FormControl {
        const validators = this.mapValidators(field.validators, field.options);
        const state = {
            value: fieldData ?? '',
            disabled: !this.formIsEnabled,
        };
        return this.fb.control(state, validators);
    }

    private mapValidators(validators: any[], options: StringDictionary): any[] {
        return validators?.map(val => validatorMapping[val.validator](val, options)) || [];
    }

    private createGroup(fields: FieldInterface[], initData: any): FormGroup {
        const controls = fields.map(field => this.createFieldControl(field, initData[field.name] ?? null));
        return new FormGroup(controls);
    }
}
