import {AbstractControl, ValidatorFn} from '@angular/forms';

export function dateFormatValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (control.value === null || control.value === '') {
            return null;
        }

        // Regular expression to match dd/mm/yyyy or d/m/yyyy format
        const dateRegEx = /^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[012])\/\d{4}$/;
        const date = control.value;
        console.log('dateFormatValidator', date);
        if (!(date instanceof Date)) {
            return {'dateFormat': {value: date}};
        }
        const day = ('0' + date.getDate()).slice(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear();

        const formattedDate = day + '/' + month + '/' + year;
        console.log('dateFormatValidator', formattedDate);
        const invalid = !dateRegEx.test(formattedDate);

        return invalid ? {'dateFormat': {value: formattedDate}} : null;
    };
}
