import { Injectable, signal, Signal } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class MainTemplateService {
  // Shared signal for menuIsPinned
  menuIsPinned = signal<boolean>(false);

  // Method to toggle the pinned state
  toggleMenu() {
    this.menuIsPinned.set(!this.menuIsPinned());
  }

  // Method to set the pinned state
  setMenuPinned(pinned: boolean) {
    this.menuIsPinned.set(pinned);
  }
}
