import {AbstractControl, ValidatorFn} from '@angular/forms';

// Custom validator function
export function dateBeforeTodayValidator(): ValidatorFn {

    return (control: AbstractControl): { [key: string]: any } | null => {
        if (control.errors && control.errors['matDatepickerParse'] && control.errors['matDatepickerParse'].text === '') {
            return null;
        }
        const dateValue = control.value;
        if (!dateValue) {
            return null; // if the control is empty at the moment, then no error
        }

        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0); // to avoid time comparison
        if (dateValue >= currentDate) {
            return {'dateBeforeToday': true}; // error, date is not before today
        }

        return null; // no error
    };

}
