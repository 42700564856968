import {Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {map} from 'rxjs/internal/operators/map';
import {GET_USER, GET_USERS, LOGIN} from '../../@graphql/operations/query';
import {ApiService} from '../../@graphql/services/api.service';
import {ILogin} from '../../@interfaces/login.interface';

@Injectable({
    providedIn: 'root'
})

export class UserService extends ApiService {    // heredamos del servicio genérico que hemos definido en api.service.ts

    constructor(apollo: Apollo) {
        super(apollo);                                    // ¿? "Ahora tenemos que hacer una llamada, lo que es el parre al constructor Parde y tendremos que pasarle lo que es Apolo"  :D
    }

    login(input: ILogin) {
        return this.mutation(LOGIN, {input}).pipe(map((result: any) => {
            return result.login;
        }));
    }
    
    list(skip: boolean = false) {                       // Recupera los usuarios enviando la consulta GET_USERS al api Graphql
        console.log('GET_USERS', GET_USERS);
        return this.query(GET_USERS, {skip}).pipe(map((result: any) => {
            return result.users.list;
        }));
    }

    get(id: string) {
        console.log('GET_USER', GET_USER);
        return this.query(GET_USER, {id, skip: false}).pipe(map((result: any) => {
            return result.user.item;
        }));
    }
}
