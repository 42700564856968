import {Component, inject, Input, ViewChild} from '@angular/core';
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu';
import {TranslateService} from '@ngx-translate/core';
import {MatDivider} from '@angular/material/divider';
import {RouterLink} from '@angular/router';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';
import {LocalStorageService} from '../../../../services/local-storage.service';
import {registerLocaleData} from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';

@Component({
    selector: 'app-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss'],
    standalone: true,
    imports: [MatIconButton,
        MatMenuTrigger,
        MatMenu,
        MatMenuItem,
        MatIcon,
        RouterLink,
        MatDivider]
})
export class UserMenuComponent {

    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

    @Input() userCompanyId = 0;
    @Input() userId = 0;
    localStorageService: LocalStorageService = inject(LocalStorageService);

    constructor(private translateService: TranslateService) {
    }

    setLanguage(languageCode: string): void {
        this.localStorageService.setItem('usrPrefs_language', languageCode);
        const localeId = languageCode === 'es' ? 'es' : 'en';
        const locale = localeId === 'es' ? localeEs : localeEn;
        registerLocaleData(locale, localeId);
        this.translateService.use(languageCode);
    }

}
