import {Component, inject, LOCALE_ID} from '@angular/core';
import {UserAuthService} from './admin/services/user/user-auth.service';
import en from '@angular/common/locales/en';
import {NgIf, registerLocaleData} from '@angular/common';
import {AppModule} from './app.module';
import {MainTemplateComponent} from './admin/shared/admin-layout/main-template/main-template.component';
import {UserLoginComponent} from './admin/user/user-login/user-login.component';
import {LoadingComponent} from './admin/shared/loading/loading/loading.component';
import {CookieModule} from 'ngx-cookie';
import {TranslateService} from '@ngx-translate/core';
import {LocalStorageService} from './services/local-storage.service';

registerLocaleData(en);

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    providers: [{provide: LOCALE_ID, useValue: 'es-*'},
        CookieModule],
    standalone: true,
    imports: [
        AppModule,
        MainTemplateComponent,
        NgIf,
        UserLoginComponent,
        LoadingComponent,
        CookieModule
    ],
})
export class AppComponent {
    title = '14all';

    userIsLogged: boolean;
    localStorageService: LocalStorageService = inject(LocalStorageService);
    translateService: TranslateService = inject(TranslateService);

    constructor(private userAuthService: UserAuthService) {
        this.translateService.use(this.localStorageService.getItem('usrPrefs_language') || 'en');
    }

    ngOnInit(): void {
//    console.log("WTF!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
//    this.userIsLogged = this.userAuthService.userIsLogged("app.component");
    }

    //probando a lo loco los distintos life cycles, este es el unico que hace que el componente detecte si el usuario esta conectado
    ngDoCheck() {
        this.userIsLogged = this.userAuthService.userIsLogged('app.component');
        //console.log("this.userIsLogged:", this.userIsLogged);
    }

    /*   setUserLoggedIn() {
        this.userIsLogged = true;
      } */
}
