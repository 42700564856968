import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private tokenKey = 'userToken';

    constructor(private http: HttpClient, private router: Router) {
    }

    getToken(): string | null {
        return localStorage.getItem(this.tokenKey);
    }

    setToken(token: string): void {
        localStorage.setItem(this.tokenKey, token);
    }

    clearToken(): void {
        localStorage.removeItem(this.tokenKey);
    }

    refreshToken(): Observable<any> {
        return this.http.post('http://192.168.1.52:4500/refresh', {}).pipe(
            tap((response: any) => {
                this.setToken(response.token);
            }),
            catchError((error) => {
                console.log('HA PETADO ELERROR', error);
                if (error.status === 400) {
                    // Clear the token
                    this.clearToken();

                    // Redirect to login page

                }

                // Re-throw the error
                return throwError(error);
            })
        );
    }
}
