import {Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/internal/operators/map';
import {UPDATE_PERSON} from '../../@graphql/operations/person/mutation/updatePerson';
import {ApiService} from '../../@graphql/services/api.service';
import {IListResult} from '../../@interfaces/list-result';
import {IPerson, IPersonSearchInput, PersonCreateInput, PersonUpdateInput} from '../../@interfaces/person.interface';
import {IRunSearch} from '../../@interfaces/run-search.interface';
// import { Person } from "../../model/person";
import {UserAuthService} from '../services/user/user-auth.service';
import {PERSONS_QUERY} from '../../@graphql/operations/query';
import {catchError} from 'rxjs/operators';
import {ADD_PERSON} from '../../@graphql/operations/person/mutation/addPerson';
import {GET_PERSON} from '../../@graphql/operations/person/query/person';
import {ValidationErrors} from "@angular/forms";

@Injectable({
    providedIn: 'root',
})
export class PersonService extends ApiService {
    constructor(private Apollo: Apollo, private userAuthService: UserAuthService) {
        super(Apollo);
    }

    get(id: string) {
        return this.query(GET_PERSON, {id}).pipe(
            map((result: any) => {
                console.log('person.service.get result:', result);
                return result.person;
            }),
            catchError((error) => {
                console.log('person.service.GET error:', error);
                return of({ error: true, message: error.message });
            })
        );
    }

    list(runSearchParams: IRunSearch<IPersonSearchInput>): Observable<IListResult<IPerson>> {
        const searchParams: IPersonSearchInput = runSearchParams.searchParams;
        const orderBy: Array<any> = runSearchParams.orderBy;
        const offset: number = runSearchParams.offset;
        const limit: number = runSearchParams.limit;
        const skip = false;
        let currentQuery = PERSONS_QUERY;

        if (runSearchParams.query) {
            currentQuery = runSearchParams.query;
        }
        
        return this.query(currentQuery, {
            searchParams,
            limit,
            offset,
            orderBy,
            // skip
        }, {}).pipe(
            map((result: any) => {
                console.log('person.service.LIST result:', result);
                return result.persons;
            }),
            catchError((error) => {
                console.log('person.service.LIST error:', error);
                return error;
            })
        );
    }

    add(person: PersonCreateInput) {
        console.log('😐 person.service ADD_PERSON', ADD_PERSON, person);
        return this.mutation(ADD_PERSON, {person, skip: false}).pipe(
            map((result: any) => {
                console.log('person.service.ADD result:', result.addPerson);
                return result.addPerson;
            }),
            catchError((error) => {
                console.log('person.service.ADD error:', error);
                throw new Error('Error adding person');
                return error;
            })
        );
    }

    update(id: number, person: PersonUpdateInput) {
        console.log('(person.service) UPDATE_PERSON', UPDATE_PERSON);
        return this.mutation(UPDATE_PERSON, {updatePersonId: id, input: person}).pipe(
            map((result: any) => {
                console.log('person.service.UPDATE result:', result);
                return result.updatePerson;
            }),
            catchError((error) => {
                console.log('person.service.UPDATE error:', error);
                return error;
            })
        );
    }



}
