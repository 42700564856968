import {ResolveFn} from '@angular/router';
import {inject} from '@angular/core';
import {PersonService} from './person.service';

export const personResolver: ResolveFn<boolean> = (route, state) => {
    const personService = inject(PersonService);
    const id = route.paramMap.get('id');
    console.log('resolving person with id:', route);
    return personService.get(id);
};
