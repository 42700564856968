import {Component, inject, OnInit} from '@angular/core';
import {LoadingIndicatorService} from '../../../../services/loading-indicator.service';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {NgIf} from '@angular/common';

@Component({
    selector: 'app-loading',
    standalone: true,
    imports: [
        MatProgressSpinner,
        NgIf
    ],
    templateUrl: './loading.component.html',
    styleUrl: './loading.component.scss'
})
export class LoadingComponent implements OnInit {
    loadingIndicatorService = inject(LoadingIndicatorService);
    isLoadig = this.loadingIndicatorService.loading();

    constructor() {

    }

    ngOnInit() {
        this.loadingIndicatorService.loadingSubject.subscribe((loading) => {
            this.isLoadig = loading;
        });
    }

}
