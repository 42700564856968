import {enableProdMode, importProvidersFrom} from '@angular/core';
import {environment} from './environments/environment';
import {bootstrapApplication} from '@angular/platform-browser';
import {AppComponent} from './app/app.component';
import {provideAnimations} from '@angular/platform-browser/animations';
import {PreloadAllModules, provideRouter, withComponentInputBinding, withDebugTracing, withPreloading} from '@angular/router';
import {routes} from './app/routes';
import {Apollo, APOLLO_OPTIONS, ApolloModule} from 'apollo-angular';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {MAT_DATE_LOCALE, provideNativeDateAdapter} from '@angular/material/core';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {COOKIE_OPTIONS, CookieModule, CookieOptionsProvider, CookieService} from 'ngx-cookie';
import {createApollo, GraphQLModule} from './app/@graphql/modules/graphql.module';
import {HttpLink} from 'apollo-angular/http';
import {AuthService} from './app/@graphql/services/auth.service';

import 'zone.js';

if (environment.production) {
    enableProdMode();
}

const createTranslationLoaderFactory = (http: HttpClient) => {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

const provideTranslation = () => ({
    defaultLanguage: 'en',
    loader: {
        provide: TranslateLoader,
        useFactory: createTranslationLoaderFactory,
        deps: [HttpClient],
    },
});

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(HttpClientModule, ApolloModule, GraphQLModule),
        provideAnimations(),
        provideNativeDateAdapter(),
        provideRouter(
            routes,
            withComponentInputBinding(),
            withPreloading(PreloadAllModules),
            withDebugTracing(), // This is for debugging
        ),
        Apollo,
        {
            provide: APOLLO_OPTIONS,
            useFactory: (httpLink: HttpLink, authService: AuthService) =>
                createApollo(httpLink, authService),
            deps: [HttpLink,
                AuthService]
        },
        importProvidersFrom([
            HttpClientModule,
            TranslateModule.forRoot(provideTranslation())
        ]),
        CookieService,
        CookieOptionsProvider,
        CookieModule,
        {provide: COOKIE_OPTIONS, useValue: {}},
        {provide: MAT_DATE_LOCALE, useValue: { useUtc: true }},
    ],
})
    .catch(err => console.error(err));
