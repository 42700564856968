import {gql} from 'apollo-angular';
import {PERSON_OBJECT} from '../personFragment';

export const UPDATE_PERSON = gql`
    mutation UpdatePerson($updatePersonId: ID!, $input: PersonUpdateInput!) {
        updatePerson(id: $updatePersonId , input: $input) {
            ...PersonObject
        }
    }
    ${PERSON_OBJECT}
`;
