<!-- <h1>app.componenet.html: userIsLogged: {{userIsLogged}}</h1> -->

<app-main-template *ngIf="userIsLogged"></app-main-template>
<app-user-login *ngIf="!userIsLogged"></app-user-login>
<app-loading></app-loading>








