<h1 *ngIf="false">
	<span *ngIf="prefix" class="prefix">{{
			prefix
		}}</span>
	{{
		heading
	}}
	<span *ngIf="suffix" [innerHTML]="suffix" class="suffix"></span>
</h1>
<div class="mt-10 mb-10   mat-elevation-z8" style="height:53px;;background:var(--mdc-elevated-card-container-color)">
	<div (isInView)="handleInView($event)" appInView appIsInViewId="page-heading"></div>
	<div class="flex items-center mx-auto font-[sans-serif] ">
		<span class="mat-mdc-card  rounded-r-full mat-elevation-z8 px-8 py-3 text-lg font-bold cursor-pointer"
		      id="page-heading">
			{{
				heading | translate
			}}
		</span>

	</div>
</div>
<h2 *ngIf="subheading !== undefined && subheading !== ''">
	<span *ngIf="prefix" class="prefix">{{
			prefix
		}}</span>
	{{
		subheading
	}}
	<span *ngIf="suffix" class="suffix">{{
			suffix
		}}</span>
</h2>

<nav
		*ngIf="!headingVisibility"
		[style.width]="document.getElementById('page-heading').parentElement.parentElement.offsetWidth +'px !important'"
		class="fixed left-15 top-0 z-10 w-full mat-elevation-z8 "
		style="height:53px;background:var(--mdc-elevated-card-container-color)"
>
	<div class="flex justify-start items-center mx-auto  font-sans">
	<span *ngIf="screenSizeService.isDesktop"
	      class="mat-mdc-card  rounded-r-full z-40 mat-elevation-z8 px-8 py-3 text-lg font-bold cursor-pointer"
	>
		{{
			heading | translate
		}}
	</span>
	</div>
</nav>

