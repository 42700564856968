import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Component, ElementRef, OnInit, ViewChildren,} from '@angular/core';
import {FormBuilder, FormControlName, FormGroup, Validators,} from '@angular/forms';
import {fromEvent, merge, Observable} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';
import {IUser} from '../../../@interfaces/user.interface';
import {UserMessageService} from '../../services/user/user-message.service';
import {UserService} from '../user.service';
import {UserAuthService} from '../../../admin/services/user/user-auth.service';
import {UserLoginFormComponent} from '../user-login-form/user-login-form.component';
import {NgIf} from '@angular/common';

@Component({
    selector: 'app-user-login',
    templateUrl: './user-login.component.html',
    styleUrls: ['./user-login.component.scss'],
    imports: [
        UserLoginFormComponent,
        NgIf
    ],
    standalone: true
})
export class UserLoginComponent implements OnInit {
    /*   @Output() userLoggedIn: EventEmitter<string> = new EventEmitter();
     */
    // Form related properties
    loginForm: FormGroup; // para gestionar el reactive form

    @ViewChildren(FormControlName, {read: ElementRef})
    formControls: ElementRef[];
    formObj: {}; // para debug y para almacenar el form en local storage
    message: { [key: string]: string } = {}; // objeto de tabla de mensajes de error?
    formStatus: string; // para debuguear
    formFieldAppearance = 'fill'; // standard, fill, outline   //PENDIENTE: Poner esto en un sitio genérico, que obtenga de usrPrefs o default
    formIsEnabled = true;
    user: IUser | undefined; // To store user person details
    backgroundImageClass = '0';
    loading = false;
    userIsLogged: boolean; // [*1] Esto es para que al hacer logout y volver a esta pantalla se refresque. Pero no tira así.
    // Lo intenté meter en una función, pero no se ejecuta el match
    formFieldsLayout: any;

    // PENDIENTE: Organizar un poco esto. Es este el sitio adecuado? No debería hacerse a la vez que this.fb.group.
    // PENDIENTE. REPLICADO! Modularizar!
    private validationMessages: {
        // Gestion de mensajes de información al usuario sobre cada campo validado
        [key: string]: { [key: string]: string | { [key: string]: string } };
    };

    constructor(
        private fb: FormBuilder,
        // private snackBar: MatSnackBar,
        private breakpointObserver: BreakpointObserver,
        private userService: UserService,
        private userMessageService: UserMessageService,
        private userAuthService: UserAuthService,
    ) {
        this.validationMessages = this.validationMessagesConfig();
    }

    // PENDIENTE: parece que esto dispara las validaciones al salir de un campo. Se puede centralizar??
    ngAfterViewInit(): void {
        const addBlurs: Observable<any>[] = this.formControls.map(
            (formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur')
        );
        merge(this.loginForm.valueChanges, ...addBlurs)
            .pipe(debounceTime(500))
            .subscribe((value) => {
                this.message = this.invalidInputs(this.loginForm);
            });
    }

    ngOnInit(): void {
        this.formFieldsLayout = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
            map(({matches}) => {
                if (matches) {
                    // console.log("Breakpoints:", Breakpoints);
                    // mobile
                    return {
                        userCode: {cols: 12},
                        password: {cols: 12},
                        rememberMe: {cols: 6},
                    };
                }

                // Default configuracion
                return {
                    userCode: {cols: 12},
                    password: {cols: 12},
                    rememberMe: {cols: 6},
                };
            })
        );
        this.setBackgroundImageClass();

        // TODO ESTO ESTA REPLICADO EN OTROS FORMS!
        // Form configuration
        this.loginFormConfiguration(this.initialDataConfig());

        // Validations
        this.loginForm.valueChanges.subscribe(() => {
            const {dirty, pristine, valid, errors, invalid, value} = this.loginForm;
            this.formStatus = JSON.stringify({
                dirty,
                pristine,
                valid,
                errors,
                invalid,
                value,
            });
        });
    }

    /*   ngAfterContentChecked() {
        this.userIsLogged = this.userAuthService.userIsLogged("user-login"); //[*1] Esto es para que al hacer logout y volver a esta pantalla se refresque. Pero no tira así.

        if (this.userIsLogged) {
          this.userLoggedIn.emit();
        }
      }
     */
    // PENDIENTE: REPLICADO!
    // Standard function for validations. Should it be somewhere in a unique place?
    invalidInputs(formgroup: FormGroup): { [key: string]: string } {
        const messages = {};
        for (const input in formgroup.controls) {
            const key = formgroup.controls[input];
            if (key instanceof FormGroup) {
                const nestedGroupMessages = this.invalidInputs(key);
                Object.assign(messages, nestedGroupMessages);
            } else {
                if (this.validationMessages[input]) {
                    messages[input] = '';
                    if (key.errors && (key.dirty || key.touched)) {
                        Object.keys(key.errors).map((messageKey) => {
                            if (this.validationMessages[input][messageKey]) {
                                messages[input] = this.validationMessages[input][messageKey];
                            }
                        });
                    }
                }
            }
        }
        // console.log("✅invalidInputs() messages:", messages);

        return messages;
    }

    onSubmit() {
        // Invalid data, show warning message to user
        if (!this.loginForm.valid) {
            this.userMessageService.showMessage(
                'Please, check errors in highlighted form fields'
            );
        }

        // Esto parece que solo muestra los datos internos para debug y guarda en local storage los datos. cosa que mola para recuperar la operacion por cortes de cualquier tipo, aunque debería hacerse en componentes genericos
        const {formObj} = this;
        const {value} = this.loginForm;
        console.log('onSubmit()', value);
        const sth = JSON.stringify({...formObj, business: value});
        try {
            localStorage.setItem('form', sth);
        } catch {
            (e) => console.log(e);
        }
    }

    ngOnDestroy(): void {
    }

    validationMessagesConfig() {
        return {
            userCode: {
                required: 'Enter user code',
                minLength: 'Enter valid user code',
            },
            password: {
                required: 'Enter password',
            },
        };
    }

    // Seed data for testing
    initialDataConfig() {
        if (false) {
            return {};
        } else {
            return {
                usuario: '',
                password: '',
            };
        }
    }

    loginFormConfiguration(initialData) {
        this.loginForm = this.fb.group({
            // userCompanyId: [0], //PENDIENTE: Obtener userCompanyId de los datos de sesión de usuario
            userCode: [
                {value: initialData.firstName, disabled: !this.formIsEnabled},
                [
                    Validators.required,
                    Validators.minLength(2),
                    Validators.maxLength(40),
                ],
            ],
            password: [
                {value: initialData.lastName, disabled: !this.formIsEnabled},
                [
                    Validators.required,
                    Validators.minLength(2),
                    Validators.maxLength(40),
                ],
            ],
            rememberMe: [],
        });
    }

    setBackgroundImageClass() {
        const totalNumberOfBackgroundImages = 11;
        this.backgroundImageClass =
            'right-pane-background-image-' +
            getRandomInt(totalNumberOfBackgroundImages);
    }
}

// PENDIENTE: Llevar a servicio general
function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}
