import {inject, Injectable} from '@angular/core';
import {ScreenSizeSignalService} from "./screen-size-signal.service";

@Injectable({
    providedIn: 'root'
})
export class ScreenSizeService {
    isDesktop: boolean;
    isTablet: boolean; // Also used for small desktops
    isMobile: boolean;
    screenSizeSignalService = inject(ScreenSizeSignalService);

    constructor() {
        this.isDesktop = window.matchMedia('(min-width: 1024)').matches;
        this.isMobile = window.matchMedia("(max-width: 767px)").matches;
        this.isTablet = window.matchMedia("(min-width: 768px) and (max-width: 1024px)").matches;

        window.matchMedia('(min-width: 768px)').addEventListener('change', e => {
            this.isDesktop = e.matches;
            console.log('MATCHMEDIA', e.matches);
        });
        window.matchMedia('(max-width: 767px)').addEventListener('change', e => {
            this.isMobile = e.matches;
        });
        window.matchMedia('(min-width: 768px) and (max-width: 1024px)').addEventListener('change', e => {
            this.isTablet = e.matches;
        });
    }
}
