import {Injectable, signal} from '@angular/core';
import {EventSignalInterface} from "../admin/shared/form/input-typeahead/Service/typeahead-signal.service";

export enum ScreenSizeEnum {
    isDesktop = 0,
    isMobile = 1
}

@Injectable({
    providedIn: 'root'
})
export class ScreenSizeSignalService {
    private screenSizeValue = ScreenSizeEnum.isDesktop;
    private sizeChangeSignal = signal<ScreenSizeEnum>(this.screenSizeValue);
    private sizeChange = this.sizeChangeSignal.asReadonly();

    public setSize(event: ScreenSizeEnum) {
        console.log('SizechangeEvent','setEvent', event)
        this.sizeChangeSignal.set(event);
    }

    public getSize(): ScreenSizeEnum {
        return this.sizeChange();
    }
}
