import {inject, NgModule} from '@angular/core';
import {HttpClient, HttpClientModule, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {InMemoryCache} from '@apollo/client/cache';
import {HttpLink} from 'apollo-angular/http';
import {ApolloLink, Observable} from '@apollo/client/core';
import {onError} from '@apollo/client/link/error';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';
import {LoadingIndicatorService} from '../../services/loading-indicator.service';
import {environment} from "../../../environments/environment";

export enum ERROR_CODES {
    REFRESH_TOKEN = 'REFRESH_TOKEN',
    REDIRECT_TO_LOGIN = 'REDIRECT_TO_LOGIN'
}

export function createApollo(httpLink: HttpLink, authService: AuthService) {
    const apiUrl = environment.apiUrl;
    const router = inject(Router);
    const loadingIndicatorService = inject(LoadingIndicatorService);
    const http = httpLink.create({uri: apiUrl, withCredentials: true});
    const httpClient = inject(HttpClient);

    const authMiddleware = new ApolloLink((operation, forward) => {
        const token = authService.getToken();
        if (token) {
            operation.setContext({
                headers: new HttpHeaders().set('Authorization', `Bearer ${token}`)
            });
        }
        return forward(operation);
    });

    const refreshTokenLink = new ApolloLink((operation, forward) => {
        return new Observable(observer => {
            let subscription;
            const token = authService.getToken();

            if (token) {
                operation.setContext({
                    headers: new HttpHeaders().set('Authorization', `Bearer ${token}`)
                });
            }
  
            subscription = forward(operation).subscribe({
                next: (result) =>  observer.next(result),
                error: (error: HttpErrorResponse) => {
                    console.log('EL ERROR ES ', error);
                    if (!error.error?.errors) {
                        observer.error(error);
                        return;
                    }
                    const errorCode = error.error?.errors[0].extensions.code;
                    console.log('EL ERRORCODE ES ', errorCode);
                    if (errorCode === ERROR_CODES.REDIRECT_TO_LOGIN) {
                        authService.clearToken();
                        router.navigate(['/login']).then(() => {
                            authService.clearToken();
                            loadingIndicatorService.hide();
                            observer.complete();
                        });
                    } else if (errorCode === ERROR_CODES.REFRESH_TOKEN) {

                        httpClient.post('http://localhost:4500/refresh', {}, {withCredentials: true}).subscribe(
                            (response: any) => {
                                const newToken = response.token;
                                if (newToken) {
                                    authService.setToken(newToken);
                                    operation.setContext({
                                        headers: new HttpHeaders().set('Authorization', `Bearer ${newToken}`)
                                    });
                                    const forward$ = forward(operation);
                                    forward$.subscribe(observer);
                                } else {
                                    observer.error(error);
                                }
                            },
                            (error) => {
                                console.log('Error refreshing token', error);
                                observer.error(error);
                            }
                        );
                    } else {
                        observer.error(error);
                    }
                },
                complete: observer.complete.bind(observer)
            });

            return () => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            };
        });
    });

    const errorLink = onError(({graphQLErrors, networkError}) => {
        if (graphQLErrors) {
            graphQLErrors.forEach(({message, locations, path}) => {
                console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
            });
        }
        if (networkError) {
            console.log(`[Network error]: ${networkError}`);
        }
    });

    return {
        link: ApolloLink.from([errorLink,
            refreshTokenLink,
            authMiddleware,
            http]),
        cache: new InMemoryCache()
    };
}

@NgModule({
    imports: [HttpClientModule],
    providers: [AuthService]
})
export class GraphQLModule {
}
