import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgIf} from '@angular/common';
import {RouterLink, RouterLinkActive} from '@angular/router';

@Component({
    selector: 'app-menu-item',
    templateUrl: './menu-item.component.html',
    styleUrls: ['./menu-item.component.scss'],
    standalone: true,
    imports: [RouterLink, NgIf, RouterLinkActive]
})
export class MenuItemComponent implements OnInit {

    @Input() itemID = '';
    @Input() link = '';
    @Input() icon = 'east'; // por defecto, para level 2, que no vienen inicializados ASI NO TIRA :(
    @Input() label = '';
    @Input() HasSubItems = false;
    @Input() itemLevel = 0;
    @Input() menuFontSize = 0;

    @Output() submenuClicked: EventEmitter<string> = new EventEmitter();

    constructor() {
    }

    ngOnInit(): void {
    }

    toggleSubItems(itemId: string): void {
        console.table('this.shownSubItems de ItemId ' + itemId);
        this.submenuClicked.emit(itemId);
    }

}
