import {AbstractControl, ValidatorFn} from '@angular/forms';

export function postalCodeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (!control.value) {
            return null;
        }
        const value = control.value;
        const isValid = /^\d{5}$/.test(value);
        return isValid ? null : { postalCode: true };
    }
}
