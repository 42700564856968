// loading-indicator.service.ts
import {Injectable, signal} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoadingIndicatorService {
    loadingSubject = new BehaviorSubject<boolean>(false);
    isLoading = signal(false);

    get loading() {
        return this.isLoading;
    }

    show() {
        if (!this.isLoading()) {
            this.isLoading.set(true);
            this.loadingSubject.next(this.isLoading());
        }
    }

    hide() {
        if (this.isLoading()) {
            this.isLoading.set(false);
            this.loadingSubject.next(this.isLoading());
        }
    }
}
