// 243. Conexión GraphQL en la aplicación y definir operación lista de personajes

import {gql} from 'apollo-angular';
import {AGENDA_OBJECT, EVENT_OBJECT, EVENT_TYPE_OBJECT, PERSON_OBJECT, USER_OBJECT,} from './fragment';

export const LOGIN = gql`
    mutation Login($input: LoginInput) {
        login(input: $input) {
            token
            user {
                userCode
                personAssociatedWithUser {
                    firstName
                    lastName
                }
                userCompanyAssociatedWithUser {
                    id
                    tradename
                    legalName
                }
            }

        }
    }
`;

export const GET_USERS = gql`
    query getUsers($skip: Boolean!) {
        users {
            status
            message
            list {
                ... on User {
                    ...UserObject
                    personAssociatedWithUser {
                        #...PersonObject  #devuelve "message: "Cannot read property 'kind' of undefined" WHY?
                        id
                        type
                        firstName
                        lastName
                        comments
                    }
                }
            }
        }
    }
    ${USER_OBJECT}
`;

/*
export const GET_USER = gql`
  query getUser($id: ID!, $skip: Boolean!) {
    user(id: $id) {
      status
      message
      item {
        ... on User {
          ...UserObject
        }
      }
    }
  }
  ${USER_OBJECT}
`;
*/

export const GET_USER = gql`
    query getUser($id: ID!, $skip: Boolean!) {
        user(id: $id) {
            status @skip(if: $skip) # si no utilizo skip me da error " {message: "Variable "$skip" is never used in operation "getUser".",…}"
            message
            item {
                ... on User {
                    ...UserObject
                    personAssociatedWithUser {
                        #...PersonObject  devuelve "message: "Cannot read property 'kind' of undefined" WHY?
                        id
                        type
                        firstName
                        lastName
                        comments
                    }
                }
            }
        }
    }
    ${USER_OBJECT}
`;

export const PERSONS_QUERY = gql`
    query persons(
        $searchParams: PersonSearchInput,
        $limit: Int,
        $offset: Int,
        $orderBy: [OrderByInput]
    ) {
        persons(
            searchParams: $searchParams,
            limit: $limit,
            offset: $offset,
            orderBy: $orderBy
        ) {

            status
            message
            totalCount
            list {
                ... on Person {
                    ...PersonObject
                }
            }
        }
    }
    ${PERSON_OBJECT}
`;
export const GET_PERSONS = gql`
    query getPersons(
        $searchParams: PersonSearchInput
        $orderBy: [OrderByInput]
        $limit: Int
        $offset: Int
        $skip: Boolean!
    ) {
        persons(
            searchParams: $searchParams
            orderBy: $orderBy
            limit: $limit
            offset: $offset
        ) {
            status @skip(if: $skip) # si no utilizo skip me da error " {message: "Variable "$skip" is never used in operation "getUser".",…}"
            refreshedToken #esto no es muy seguro, no? Donde se debería devolver el refreshed token al navegador? Por otro lado, el login lo devuelve asi, en un campo de respuesta del api
            message
            ... on Result {
                list {
                    ... on Person {
                        ...PersonObject
                    }
                }
                totalCount
            }
        }
    }
    ${PERSON_OBJECT}
`;

// no sobra on Result...?
export const GET_EVENT_TYPES = gql`
    query getEventTypes($skip: Boolean!) {
        eventTypes {
            status @skip(if: $skip) # si no utilizo skip me da error "{errors: [{message: "Variable "$skip" is never used in operation "getEvents".",…}]}" porqué no da error en getUsers?
            message
            list {
                ... on EventType {
                    ...EventTypeObject
                }
            }
        }
    }
    ${EVENT_TYPE_OBJECT}
`;

export const GET_EVENTS = gql`
    query getEvents(
        $searchParams: EventSearchInput
        $orderBy: [OrderByInput]
        $skip: Boolean!
    ) {
        events(searchParams: $searchParams, orderBy: $orderBy) {
            status @skip(if: $skip) # si no utilizo skip me da error "{errors: [{message: "Variable "$skip" is never used in operation "getEvents".",…}]}" porqué no da error en getUsers?
            message
            list {
                ... on Event {
                    ...EventObject
                    eventType {
                        description
                        color
                    }
                }
            }
        }
    }
    ${EVENT_OBJECT}
`;

export const GET_AGENDAS = gql`
    query getAgendas($skip: Boolean!) {
        agendas {
            status @skip(if: $skip) # si no utilizo skip me da error "{errors: [{message: "Variable "$skip" is never used in operation "getEvents".",…}]}" porqué no da error en getUsers?
            message
            list {
                ... on Agenda {
                    ...AgendaObject
                }
            }
        }
    }
    ${AGENDA_OBJECT}
`;
