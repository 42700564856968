import {LOCALE_ID, NgModule} from '@angular/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {CommonModule, registerLocaleData} from '@angular/common';
import {Apollo, ApolloModule} from 'apollo-angular';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {FormGeneratorNewService} from './admin/shared/form/form-generator-new.service';
import {CookieModule, CookieOptionsProvider, CookieService} from 'ngx-cookie';
import {GraphQLModule} from './@graphql/modules/graphql.module';
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
    MomentDateAdapter,
    provideMomentDateAdapter
} from "@angular/material-moment-adapter";
import 'moment/locale/es';

export function HttpLoaderFactory(http: HttpClient) {
    // Customize the path as needed; this is just a starting point
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const localeId = localStorage.getItem('usrPrefs_language') === 'es' ? 'es' : 'en';
const locale = localeId === 'es' ? localeEs : localeEn;
// registerLocaleData(locale, localeId);
registerLocaleData(localeEs, 'es');
export const CUSTOM_DATE_FORMAT = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'DD/MM/YYYY',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        HttpClientModule,
        ApolloModule,
        GraphQLModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient] // Dependency on HttpClient
            }
        }),
        CookieModule
    ],
    providers: [ApolloModule,
        Apollo,
        HttpClient,
        TranslateModule,
        FormGeneratorNewService,
        CookieService,
        CookieOptionsProvider,
        CookieModule,
        {provide: LOCALE_ID, useValue: 'es'},
        {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},

        // Moment can be provided globally to your app by adding `provideMomentDateAdapter`
        // to your app config. We provide it at the component level here, due to limitations
        // of our example generation script.

    ],
    exports: [HttpClientModule,
        ApolloModule,
        TranslateModule,
    ],
    bootstrap: []
})
export class AppModule {
}
