 import {AbstractControl, ValidatorFn} from '@angular/forms';

export function validOptionValidator(options: any[] | any | { [key: string]: any }): ValidatorFn {

    return (control: AbstractControl): { [key: string]: any } | null => {

        if (!control.value || Object.keys(options).includes(control.value) || Object.values(options).includes(control.value)) {
            return null; // if validation is passed, return null
        }

        return {'invalidOption': {value: control.value}}; // return an object if the validation fails
    };
}
