/*
Gestión de APIs Apollo GraphQL
Anartz: 244. Crear el servicio principal de la API y definir la función para las consultas
*/
import {Injectable} from '@angular/core';
import {DocumentNode} from '@apollo/client';
import {Apollo} from 'apollo-angular';
import {map} from 'rxjs/internal/operators/map';
import {HttpHeaders} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class ApiService {
    private authContext = {
        headers: new HttpHeaders({
            Authorization: `Bearer ${localStorage.getItem('userToken')}`
        })
    };

    constructor(private apollo: Apollo) {
    }

    // Implementación de Función "query" común para todas las queries de la app
    // protected => La función sólo puede ser accedida desde el hijo. Es decir, no se podrá llamar a "query" más que desde las clases que hereden "ApiService".
    // DocumentNode: La query (ej GET_USERS)
    // variables, por ejemplo, el id en caso de consultar un documento concreto
    // context, para pasar el token, por ejemplo
    protected query(query: DocumentNode, variables: object = {}, context: object = {}) {
        context = {...this.authContext, ...context};
        return this.apollo.query({   // envia la consulta
            query,
            variables,
            context,
            fetchPolicy: 'network-only'     // ?
        }).pipe(
            map((result) => result.data)
        );
    }

    protected mutation(mutation: DocumentNode, variables: object = {}, context: object = {}) {
        context = {...this.authContext, ...context};
        return this.apollo.mutate({
            mutation,
            variables,
            context,
        }).pipe(map((result) => {
            return result.data;
        }));
    }

    protected subscription(subscription: DocumentNode, variables: object = {}, context: object = {}) {
        return this.apollo.subscribe({
            query: subscription,
            variables,
            context
        }).pipe(
            map((result) => {
                return result.data;
            })
        );
    }
}
