/*
Shows the links for each register displayed in datatables search results
*/
import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

import {IFunctionsLinksParams} from '../../../@interfaces/functionsLinksParams.interface';
import {RouterLink} from '@angular/router';
import {MatIcon} from '@angular/material/icon';
import {MatMenuTrigger, MatMenu, MatMenuItem} from '@angular/material/menu';
import {MatIconButton} from '@angular/material/button';
import {NgIf, NgFor} from '@angular/common';

@Component({
    selector: 'datatable-register-links',
    templateUrl: './datatable-register-links.component.html',
    styleUrls: ['./datatable-register-links.component.scss'],
    standalone: true,
    imports: [NgIf, MatIconButton, MatMenuTrigger, MatIcon, MatMenu, NgFor, MatMenuItem, RouterLink]
})
export class DatatableRegisterLinksComponent implements OnInit, OnChanges {

    @Input() itemID = '';                            // Identificador de la entidad tratada, usuario, cliente, etc.
    @Input() FunctionsLinksParams: IFunctionsLinksParams[];  // Parámetros para montar los enlaces: icono, caption, link, etc.
    @Input() registerLinksFormatIsCollapsibleMenu: boolean; // Formato de presentación de los links: CollapsibleMenu, IconList (!CollapsibleMenu)

    ngOnInit(): void {
        for (let i = 0; i < this.FunctionsLinksParams.length; i++) {
            if (this.FunctionsLinksParams[i].link) {
                this.FunctionsLinksParams[i].link = this.FunctionsLinksParams[i].link.replace(/{}/, this.itemID);
            }
            if (this.FunctionsLinksParams[i].click) {
                const click = this.FunctionsLinksParams[i].click;
                this.FunctionsLinksParams[i].click = () => ((itemId: any) => click(itemId))(this.itemID);
            }
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.ngOnInit();
    }

}
