import {AbstractControl, AsyncValidatorFn, ValidationErrors} from "@angular/forms";
import {catchError, map, mergeMap, startWith, switchAll, switchMap, take, tap} from "rxjs/operators";
import {combineLatest, firstValueFrom, from, Observable, of, Subject} from "rxjs";
import {IPerson, IPersonSearchInput} from "../../../../@interfaces/person.interface";
import {IRunSearch} from "../../../../@interfaces/run-search.interface";
import {PERSONS_TYPEAHEAD_QUERY} from "../../../../@graphql/operations/person/query/personsTypeAhead";
import {IListResult} from "../../../../@interfaces/list-result";
import {PersonService} from "../../person.service";
import {inject} from "@angular/core";


// Custom async validator factory
export function identificationInUseAsyncValidator(personService: PersonService): AsyncValidatorFn {

    console.log('IDENTIFICATION VALIDATOR', personService)
    return (identificationControl: AbstractControl): Observable<ValidationErrors | null> => {
        console.log('DENTRO DE IDENFICATION VALIDATOR', identificationControl)
        if (!identificationControl.parent) {
            return of(null);
        }

        const identificationTypeControl = identificationControl.parent.get('identificationType');
        if (!identificationControl.value || !identificationTypeControl?.value) {
            return of(null);
        }

        const idControl = identificationControl.parent.parent.get('default.id');
        const id = idControl?.value || null;


        const personSearchInput: IPersonSearchInput = {
            identification: identificationControl.value,
            identificationType: identificationTypeControl.value,
            status: 'A',
            type: 'C',
            id: id

        };

        const runSearchParams: IRunSearch<IPersonSearchInput> = {
            searchParams: personSearchInput,
            orderBy: [{field: 'firstName', direction: 'asc'}],
            limit: 1,
            isOnlyOOL: true,
            query: PERSONS_TYPEAHEAD_QUERY
        };
        return personService.list(runSearchParams).pipe(
            tap(result => console.log('FROM1', result)),
            map((result: IListResult<IPerson>) => result.totalCount > 0),
            map(isInUse => {
                return (isInUse ? { 'identificationInUse': true } : null);
            }),
            catchError((err) => {
                console.log('ERROR EN PERSONSERVICCE LIST', err)
                return of(null)
            })
        );
    };
}
