import {AbstractControl, ValidatorFn} from '@angular/forms';

export function patternNameValidator(): ValidatorFn {
    const regex = /^[A-Za-z]+(?:[ A-Za-z]+)*$/;
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (!control.value) {
            return null;
        }

        const valid = regex.test(control.value.trim());
        return valid ? null : {'pattern': {value: control.value}};
    };
}
