import {Component, OnInit, Input, Output, EventEmitter, OnDestroy, inject, effect} from '@angular/core';

// [*fullscreen] para fullscreen https://stackblitz.com/edit/angular-go-full-screen-f11-key?file=src%2Fapp%2Fapp.component.ts
import {Inject} from '@angular/core';
import {DOCUMENT, Location, NgIf} from '@angular/common';
import {UserAuthService} from '../../../services/user/user-auth.service';
import {LocalStorageService} from '../../../../services/local-storage.service';
import {USER_ROLE} from '../../../../general/classifications';
import {MatCard, MatCardTitle, MatCardActions} from '@angular/material/card';
import {UserMenuComponent} from '../user-menu/user-menu.component';
import {MatBadge} from '@angular/material/badge';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton, MatButton} from '@angular/material/button';
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {MatTooltip} from "@angular/material/tooltip";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {BreakpointObserver} from "@angular/cdk/layout";
import {delay, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {ScreenSizeEnum, ScreenSizeSignalService} from "../../../../services/screen-size-signal.service";
import {ScreenSizeService} from "../../../../services/screen-size.service";


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        MatIconButton,
        MatIcon,
        MatBadge,
        UserMenuComponent,
        MatCard,
        MatCardTitle,
        MatCardActions,
        MatButton,
        MatMenuTrigger,
        MatMenu,
        MatMenuItem,
        MatTooltip,
    ],

})
export class HeaderComponent implements OnInit, OnDestroy {
    @Input() sidenavOpened = true; // string? no deberia ser boolean?

    @Output() menuButtonClicked: EventEmitter<string> = new EventEmitter();
    showExpanded ;
    screenSizeSignalService = inject(ScreenSizeSignalService);
    screenSize: ScreenSizeEnum = ScreenSizeEnum.isDesktop;
    screenSizeService = inject(ScreenSizeService);
    isTablet: boolean;
    protected readonly ScreenSizeEnum = ScreenSizeEnum;

    constructor(
        @Inject(DOCUMENT) private document: any,
        private userAuthService: UserAuthService,
        private localStorageService: LocalStorageService,
        private location: Location,
        private observer: BreakpointObserver,
    ) {
        if (this.isTablet || this.screenSize === ScreenSizeEnum.isMobile) {
            this.showExpanded = false;
        }
        else {
            this.showExpanded = this.localStorageService.getItem('usrPrefs_headerActionsExpanded') !== 'false';
        }


        /**
         * Controlamos el tamaño de la pantalla para mostrar u ocultar el menú de acciones
         * TODO getItem en signal
         */
        effect(() => {
            this.screenSize = this.screenSizeSignalService.getSize();
            this.setIsTablet();
            if ((this.screenSize === ScreenSizeEnum.isMobile || this.isTablet) && this.showExpanded === true ) {
                this.showExpanded = false;
            }
            if (this.screenSize === ScreenSizeEnum.isDesktop && !this.isTablet) {
                this.showExpanded = this.localStorageService.getItem('usrPrefs_headerActionsExpanded') === 'true';
            }

        });
    }

    elem: any; // [*fullscreen]

    // Un interface IUser para estos datos?
    decodedUserToken: any = {};
    userId = 0;
    userCompanyId = 0;
    userFullName = '';
    userCompanyName = '';
    userRoleDescription = '';
    destroy$ = new Subject();

    ngOnInit(): void {
        this.elem = document.documentElement; // [*fullscreen]
        this.userAuthService.getUserSessionInfo();
        this.userSessionInfo();
        this.setIsTablet();
    }
    toggleTemplate() {
        this.showExpanded = !this.showExpanded;
        this.localStorageService.setItem('usrPrefs_headerActionsExpanded', JSON.stringify(this.showExpanded));
        return this.showExpanded;

    }

    mainMenuToggle(): void {
        console.log('mainMenuToggle()');
        this.menuButtonClicked.emit();
    }

    // [*fullscreen]
    openFullscreen() {
        if (this.elem.requestFullscreen) {
            this.elem.requestFullscreen();
        } else if (this.elem.mozRequestFullScreen) {
            /* Firefox */
            this.elem.mozRequestFullScreen();
        } else if (this.elem.webkitRequestFullscreen) {
            /* Chrome, Safari and Opera */
            this.elem.webkitRequestFullscreen();
        } else if (this.elem.msRequestFullscreen) {
            /* IE/Edge */
            this.elem.msRequestFullscreen();
        }
        console.table('tras abrir fullscreen: ' + this.elem);
    }

    smallScreen(): boolean {
        if (window.innerWidth <= 800) {
            return true;
        }
        return false;
    }
    /* Close fullscreen */
    closeFullscreen() {
        // if (document.exitFullscreen) {  //error TS2774: This condition will always return true since this function is always defined. Did you mean to call it instead? Entiendo que quiere decir "si existe la funcion en el navegador..." No se porque no la pilla
        if (true) {
            this.document.exitFullscreen();
        } else if (this.document.mozCancelFullScreen) {
            /* Firefox */
            this.document.mozCancelFullScreen();
        } else if (this.document.webkitExitFullscreen) {
            /* Chrome, Safari and Opera */
            this.document.webkitExitFullscreen();
        } else if (this.document.msExitFullscreen) {
            /* IE/Edge */
            this.document.msExitFullscreen();
        }
    }

    pendingWork(msg: string): void {
        console.log('pendingWork: ' + msg);
    }

    userSessionInfo() {
        this.userFullName = this.userAuthService.userFullName;
        this.userCompanyName = this.userAuthService.userCompanyName;
        this.userId = this.userAuthService.userId;
        this.userCompanyId = this.userAuthService.userCompanyId;
        this.userRoleDescription = USER_ROLE[this.userAuthService.userRole];
    }

    // https://nils-mehlhorn.de/posts/angular-navigate-back-previous-page
    goBack(): void {
        this.location.back();
    }

    ngOnDestroy() {
        this.destroy$.next(null);
        this.destroy$.complete();
    }


    private setIsTablet() {
        this.isTablet = this.screenSizeService.isTablet;
    }
}
