import {gql} from 'apollo-angular';

export const PERSON_OBJECT = gql`
    fragment PersonObject on Person {
        id
        type
        status
        title
        firstName
        lastName

        identificationType
        identification
        nationality
        birthdate
        phoneNumber
        email
        referrer
        Referrer {
            id
            type
            status
            name
            firstName
            lastName
            birthdate
            identification
            typeahead
        }

        comments
        createdBy
        updatedBy
        createdAt
        updatedAt
        addresses {
            ... on Address {
                id
                address
                town
                postalCode
                provinceCode
                countryCode
                status
                default
                addressType
                relationId
                createdBy
                updatedBy
                createdAt
                updatedAt



            }
        }
        relatedPersons {
            ... on RelatedPerson {
                userCompanyId
                personId
                relatedPersonId
                relationType
                createdAt
                updatedAt
                relatedPerson {
                    ... on Person {
                        id
                        type
                        status
                        title
                        firstName
                        lastName
                        identificationType
                        identification
                        typeahead
                    }
                }
            }
        }
    }
`;
