import {AbstractControl, ValidatorFn} from '@angular/forms';
//TODO TODO
export function identificationValidator(): ValidatorFn {
    //const regex = /^[A-Za-z]+(?:[ A-Za-z]+)*$/;
    const DNI_REGEX = /^(\d{8})([A-Z])$/;
    const CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
    const NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (!control.value) {
            return null;
        }
        //return {'document': {value: control.value}};
        return null;
        //const valid = regex.test(control.value.trim());
       // return valid ? null : {'pattern': {value: control.value}};
    };
}
