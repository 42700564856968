import {gql} from 'apollo-angular';
import {PERSON_OBJECT} from '../personFragment';

export const GET_PERSON = gql`
    query person($id: ID!) {
        person(id: $id) {
            ... on Person {
                ...PersonObject
            }

        }
    }
    ${PERSON_OBJECT}
`;
