import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class RefreshTokenService {

    private refreshTokenCookieName = 'refreshToken';

    constructor() {
    }

    setRefreshToken(refreshToken: string) {

    }

    //getRefreshToken(): string {
    //    //return this.cookie.get(this.refreshTokenCookieName);
    //}

    removeRefreshToken() {
        //this.cookie.delete(this.refreshTokenCookieName);
    }
}
