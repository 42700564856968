<a (click)="selectDarkTheme()" *ngIf="theme == 'light'">
	<mat-icon>
		dark_mode
	</mat-icon>
</a>

<a (click)="selectLightTheme()" *ngIf="theme == 'dark'">
	<mat-icon>
		light_mode
	</mat-icon>
</a>

<!-- 
<a *ngIf="theme != 'light' && theme != 'dark'">
    WTF {{theme}}
</a> -->
