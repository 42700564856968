import {PersonCreateInput} from "./person.interface";
import {CompanyCreateInput} from "./company/company";
import {PersonCompanyRelationTypeStatus} from "./person-company-relation-type.interface";
import {AddressCreateInput} from "./address/addresCreateInput.interface";

export  enum StatusEnum {
    ACTIVE = 'A',
    PENDING = 'P',
    CANCELED = 'C'
}
export enum RelationTypeEnum {
    CLIENT = 'C',
    REFERRER = 'R',
    RELATED_PERSON = 'P',
}

export enum PersonCompanyTypeEnum {
    PERSON = 'P',
    COMPANY = 'C'
}


export interface PersonCompanyAdditionalInformationSearchInput {
    id?:  string;
    relationType: RelationTypeEnum,
    personCompanyId?: number,
    personCompanyType: string,
    createdAtFrom?: String,
    createdAtTo?: String,
    status?: StatusEnum;
    email?: string;
    phoneNumber?: string;
    comments?: string;
    //campos de persona
    firstName?: string;
    lastName?: string;
    identification?: string;


    //campos de company
    tradename?: string;
    legalName?: string;
    legalIdentification?: string;

    //campos búsqueda clientes
    name?: string;
    freeSearch?: string;

    freeSearchMode?: 'exact' | 'contains';
    advancedSearchMode?: 'exact' | 'contains';
}


export interface PersonCompanyAdditionalInformationCreateInput {
    id?: number;
    relationType: RelationTypeEnum;
    personCreateInput?: PersonCreateInput;
    companyCreateInput?: CompanyCreateInput;
    status: PersonCompanyRelationTypeStatus;
    phoneNumber?: string;
    email?: string;
    comments?: string;
    referrerId?: number;
    addresses?: AddressCreateInput[];
}
