import {gql} from 'apollo-angular';
import {PERSON_OBJECT} from '../personFragment';

export const ADD_PERSON = gql`
    mutation addPerson($person: PersonCreateInput!) {
        addPerson(input: $person) {
            status
            message
            timeStamp
            data {
                ...PersonObject
            }
            errors {
                field
                type
            }    
        }
    }
    ${PERSON_OBJECT}
`;
