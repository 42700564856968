<!-- TOGGLER: collapsible: {{registerLinksFormatIsCollapsibleMenu}}
 -->

<button
		(click)="toggleRegisterLinksFormat()"
		aria-label="Expand options menu"
		color="basic"
		mat-flat-button>

	<mat-icon
			*ngIf="registerLinksFormatIsCollapsibleMenu"
			title="Show function icons">more_horiz
	</mat-icon>
	<mat-icon
			*ngIf="!registerLinksFormatIsCollapsibleMenu"
			title="Hide function icons">more_vert
	</mat-icon>

</button>
