import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatIcon} from '@angular/material/icon';
import {NgIf} from '@angular/common';
import {MatButton} from '@angular/material/button';

@Component({
    selector: 'app-datatable-register-links-toggler',
    templateUrl: './datatable-register-links-toggler.component.html',
    styleUrls: ['./datatable-register-links-toggler.component.scss'],
    standalone: true,
    imports: [
        MatButton,
        NgIf,
        MatIcon,
    ],
})
export class DatatableRegisterLinksTogglerComponent {

    @Input() registerLinksFormatIsCollapsibleMenu: boolean;

    @Output() registerLinksFormatToggleClicked: EventEmitter<string> = new EventEmitter();

    // registerLinksFormatIsCollapsibleMenu = true;      //PENDIENTE: Guardar en local storage la preferencia del usuario

    constructor() {
    }

    // Cambia el formato de presentación de los enlaces a las funciones de cada registro (usuario) mostrado
    public toggleRegisterLinksFormat(): void {
        /*     if (this.registerLinksFormat == "IconList") {
              this.registerLinksFormat = "CollapsibleMenu";
            } else {
              this.registerLinksFormat = "IconList";
            }
         */
        // this.registerLinksFormatIsCollapsibleMenu = !this.registerLinksFormatIsCollapsibleMenu;
        this.registerLinksFormatToggleClicked.emit();

        // console.log("this.registerLinksFormat =" + this.registerLinksFormat);
    }
}
