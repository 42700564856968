<app-page-heading [heading]="heading"></app-page-heading>

<textarea *ngIf="true" class="debug">
  PENDIENTE:
  - orderBy. Incluir en la query para que la extracción
  de datos aparezca ya con un orden, ahora aparece aleatorio.
  - RENDIMIENTO! Veo que en vez de un join User-Person se está lanzando una select sobre User y para cada registro recuperado se hace un select a Person!!
</textarea>

<!-- esto deberia ir en un sitio global -->
<mat-spinner *ngIf="loading"></mat-spinner>

<button
		aria-label="Example icon button with a heart icon"
		class="button-add"
		mat-fab
>
	<a routerLink="/user/add">
		<mat-icon>add</mat-icon>
	</a>
</button>

<mat-form-field appearance="standard">
	<mat-label>Filtrar</mat-label>
	<input
			#input
			(keyup)="applyFilter($event)"
			matInput
			placeholder="Ej. García"
	/>
</mat-form-field>

<!-- SEARCH: collapsible: {{registerLinksFormatIsCollapsibleMenu}} -->

<div class="mat-elevation-z8">
	<table [dataSource]="dataSource" mat-table matSort>

		<!-- Menu Column -->
		<ng-container matColumnDef="ops">
			<th *matHeaderCellDef mat-header-cell>
				<app-datatable-register-links-toggler
						(registerLinksFormatToggleClicked)="toggleRegisterLinksFormat()"
						[registerLinksFormatIsCollapsibleMenu]="registerLinksFormatIsCollapsibleMenu"></app-datatable-register-links-toggler>
			</th>

			<td
					*matCellDef="let row"
					[ngClass]="{
          'collapsible-menu': registerLinksFormatIsCollapsibleMenu,
          'icon-list': !registerLinksFormatIsCollapsibleMenu
        }"
					mat-cell
			>
				<datatable-register-links
						[FunctionsLinksParams]="FunctionsLinksParams"
						[itemID]="row.id"
						[registerLinksFormatIsCollapsibleMenu]="registerLinksFormatIsCollapsibleMenu"
				>
				</datatable-register-links>
			</td>
		</ng-container>

		<!-- ID Column -->
		<ng-container matColumnDef="id">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>ID</th>
			<td *matCellDef="let row" mat-cell>{{
					row.id
				}}
			</td>
		</ng-container>

		<!-- Image Column -->
		<ng-container matColumnDef="image">
			<th *matHeaderCellDef mat-header-cell mat-sort-header></th>
			<td *matCellDef="let row" mat-cell>
				<a routerLink="/user/{{ row.personId }}"
				><img
						class="person-image"
						onerror="this.src='/assets/img/users/0.png';"
						ngSrc="{{ '/assets/img/users/' + row.personId + '.png' }}"
						alt="" fill=""/></a>
			</td>
		</ng-container>

		<!-- userCode Column -->
		<ng-container matColumnDef="userCode">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>Usuario</th>
			<td *matCellDef="let row" mat-cell>{{
					row.userCode
				}}
			</td>
		</ng-container>

		<!-- name Column -->
		<ng-container matColumnDef="name">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>Nombre</th>
			<td *matCellDef="let row" mat-cell>
				{{
					row.personAssociatedWithUser.firstName
				}}
				{{
					row.personAssociatedWithUser.lastName
				}}
			</td>
		</ng-container>

		<!-- role Column -->
		<ng-container matColumnDef="role">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>Perfil</th>
			<td *matCellDef="let row" mat-cell>{{
					userRole[row.role]
				}}
			</td>
		</ng-container>

		<!-- comments Column -->
		<ng-container matColumnDef="comments">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>Observaciones</th>
			<td *matCellDef="let row" mat-cell>{{
					row.comments
				}}
			</td>
		</ng-container>

		<!-- createdAt Column -->
		<ng-container matColumnDef="createdAt">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>Fecha alta</th>
			<td *matCellDef="let row" mat-cell>{{
					row.createdAt | date: medium
				}}
			</td>
		</ng-container>

		<tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
		<tr
				(click)="clickedRow(row)"
				*matRowDef="let row; columns: displayedColumns"
				[class.row-is-clicked]="clickedRows.has(row)"
				mat-row
		></tr>

		<!-- Row shown when there is no matching data. -->
		<tr *matNoDataRow class="mat-row">
			<td class="mat-cell" colspan="4">
				No data matching the filter "{{
					input.value
				}}"
			</td>
		</tr>
	</table>

	<!-- paginacion-->
	<mat-paginator
			[pageSizeOptions]="[5, 10, 20]"
			aria-label="Select page of users"
			showFirstLastButtons
	>
	</mat-paginator>
</div>
