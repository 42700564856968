<div class="elm-level{{ itemLevel }} menu-item-zoom-{{ menuFontSize }}">
	<div class="highlight"></div>

	<div class="icon">
		<a routerLink="{{ link }}" routerLinkActive="active">
      <span class="material-icons">
        {{
		      icon ? icon : "east"
	      }}
      </span>
		</a>
	</div>

	<div class="label">
		<a routerLink="{{ link }}" routerLinkActive="active">{{
				label
			}}</a>
	</div>

	<div class="expand">
		<a (click)="toggleSubItems(itemID)" *ngIf="HasSubItems">
			<span class="material-icons"> expand_more </span>
		</a>
	</div>
</div>
