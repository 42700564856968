<div class="user-login-form">
	<a routerLink="/login/"><img class="logo" src="/assets/img/logos/logo-14all.svg"/></a>
	<h1>Sign in</h1>
	<p>Don't have an account? <a routerLink="">Sign up</a></p>

	<form (ngSubmit)="onSubmit()" [formGroup]="loginForm">

		<mat-grid-list cols="12" rowHeight="80px">
			<mat-grid-tile
					[colspan]="(formFieldsLayout | async)?.userCode.cols"
					[rowspan]="1"
			>
				<mat-form-field appearance="{{ formFieldAppearance }}">
					<mat-label>Usuario</mat-label>
					<input
							formControlName="userCode"
							matInput
							placeholder="mariaperez"
							value="rubencio"
					/>
					<mat-hint>{{
							message.userCode
						}}
					</mat-hint>
					<mat-error *ngIf="message && message.userCode">{{
							message.userCode
						}}
					</mat-error>
				</mat-form-field>
			</mat-grid-tile>

			<mat-grid-tile
					[colspan]="(formFieldsLayout | async)?.password.cols"
					[rowspan]="1"
			>
				<mat-form-field appearance="{{ formFieldAppearance }}">
					<mat-label>Password</mat-label>
					<input
							formControlName="password"
							matInput
							placeholder=""
							type="password"
					/>
					<mat-icon matSuffix>visibility</mat-icon>
					<mat-hint></mat-hint>
					<mat-error *ngIf="message && message.password">{{
							message.password
						}}
					</mat-error>
				</mat-form-field>
			</mat-grid-tile>

			<mat-grid-tile
					[colspan]="(formFieldsLayout | async)?.rememberMe.cols"
					[rowspan]="1"
			>
				<mat-checkbox class="example-margin">Remember me</mat-checkbox>
			</mat-grid-tile>

			<mat-grid-tile [colspan]="6" [rowspan]="1">
				<a routerLink="">Forgot password?</a>
			</mat-grid-tile>

			<mat-grid-tile [colspan]="12" [rowspan]="1">
				<div class="login-submit-button">
					<button
							(click)="login(loginForm.value)" aria-label="Login"
							color="primary"
							mat-raised-button
							title="Login"
							type="submit"
					>
						<mat-icon>login</mat-icon>
						Login
					</button>
				</div>
			</mat-grid-tile>
		</mat-grid-list>
	</form>
</div>

