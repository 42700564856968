import {Component, inject, ViewChild} from '@angular/core';
import {BreakpointObserver} from '@angular/cdk/layout';
import {MatSidenav, MatSidenavContainer, MatSidenavContent} from '@angular/material/sidenav';
import {delay} from 'rxjs/operators';
import {LocalStorageService} from '../../../../services/local-storage.service';
import {FooterComponent} from '../footer/footer.component';
import {HeaderComponent} from '../header/header.component';
import {MainMenuComponent} from '../menu/main-menu/main-menu.component';
import {MatIcon} from '@angular/material/icon';
import {MatButton} from '@angular/material/button';
import {RouterLink, RouterOutlet} from '@angular/router';
import {NgClass, NgIf} from '@angular/common';
import {
    ScreenSizeEnum,
    ScreenSizeSignalService,

} from "../../../../services/screen-size-signal.service";
import { MainTemplateService } from './services/main-template.service';

@Component({
    selector: 'app-main-template',
    templateUrl: './main-template.component.html',
    styleUrls: ['./main-template.component.scss'],
    standalone: true,
    imports: [MatSidenavContainer, MatSidenav, NgClass, RouterLink, NgIf, MatButton, MatIcon, MainMenuComponent, MatSidenavContent, HeaderComponent, RouterOutlet, FooterComponent]
})


export class MainTemplateComponent {
    // "We add a ViewChild decorator to get the mat-sidenav component reference in our code"
    @ViewChild(MatSidenav)
        // "we name it "sidenav" and... ???"
    sidenav!: MatSidenav;

    menuIsPinned: boolean;   // El menu está 'fijado', no se oculta
    menuIsExpanded: boolean; // El menu está expandido en toda su plenitud

    sidenavBackgroundImage: number;  // Clase css del menu
    sidenavBackgroundColor: number;  // Clase css del menu

    menuFontSize: number;
    screenSizeSignalService = inject(ScreenSizeSignalService);
    mainTemplateService = inject(MainTemplateService);

    // "We include a "BreakpointObserver" in our constructor
    constructor(private observer: BreakpointObserver, private localStorageService: LocalStorageService) {
        this.menuIsPinned = this.initialParamValue('menuIsPinned') === 1 ? true : false;   // Indica si el usuario a pinchado en el botón de bloqueo para que no se encoja el menu
        this.sidenavBackgroundColor = this.initialParamValue('sidenavBackgroundColor');
        this.sidenavBackgroundImage = this.initialParamValue('sidenavBackgroundImage');
        this.menuFontSize = this.initialParamValue('menuFontSize');

        this.menuIsExpanded = this.menuIsPinned;  // Indica si el menú está desplegado (no encogido). El estado inicial depende de si está pinneado o no

        // console.log('INIC this.menuIsPinned ' + this.menuIsPinned)
        // console.log('INIC this.menuIsExpanded ' + this.menuIsExpanded)
    }


    ngOnInit() {
    }


    mainMenuToggle(): void {
        // console.log('mainMenuToggle()')
        this.sidenav.toggle();
    }


    togglePinMenu(): void {
        this.menuIsPinned = !this.menuIsPinned;
        if (this.menuIsPinned) {
            this.sidenav.mode = 'side';
        }

        this.localStorageService.setItem('usrPrefs_menuIsPinned', JSON.stringify(this.menuIsPinned ? 1 : 0));      // Guardamos la preferencia del usuario en local storage
        // console.log('pinMenu() / this.menuIsPinned = ' + this.menuIsPinned + 'this.sidenav.mode == ' + this.sidenav.mode)
        this.mainTemplateService.setMenuPinned(this.menuIsPinned);
    }

    expandMenu(): void {
        this.menuIsExpanded = true;
        // console.log('expandMenuToggle this.menuIsExpanded = ' + this.menuIsExpanded)
    }

    contractMenu(): void {
        if (!this.menuIsPinned) {
            this.menuIsExpanded = false;
        }
        // console.log('expandMenuToggle this.menuIsExpanded = ' + this.menuIsExpanded)
    }

    // "Any code to access the sidenav should be in the ngAfterViewInit because otherwise, our sidenav instance might not have been initialized"
    ngAfterViewInit() {
        // console.log('ngAfterViewInit...')
        /*Creamos un observer para que esté al loro del ancho de la pantalla y actualice en consecuencia el "modo" del "mat-sidenav" */
        this.observer
            // .observe(['(max-width: 800px)']) RR
            // "The observer can take any number of breakpoints, if any of them matches all subscriptions to the method are notified"
            // "so let's add the observe on a maximum width of 800 pixels..."
            .observe(['(max-width: 1024px)'])
            //.pipe(delay(1)) // ????
            // "...and then subscribe to it... and get a response 'res'"
            .subscribe((res) => {
                if (res.matches) {
                    this.screenSizeSignalService.setSize(ScreenSizeEnum.isMobile)
                    // "..we are in a small screen... so we set the mode of the sidenav to 'over'"
                    this.sidenav.mode = 'over';
                    // console.log('ngAfterViewInit(): this.sidenav.mode < ' + this.sidenav.mode)
                    // "...and we close the sidenav because we don't want to show it"
                    this.sidenav.close();
                } else {
                    // "if it doesn't match we are in a larger screen we set the mode to 'side'"
                    this.screenSizeSignalService.setSize(ScreenSizeEnum.isDesktop)

                    this.sidenav.mode = 'side';
                    // console.log('ngAfterViewInit(): this.sidenav.mode < ' + this.sidenav.mode)
                    // "and open the sidenav"
                    this.sidenav.open();
                    // console.log('2this.sidenav.opened=' + this.sidenav.opened);
                }
            });
        // this.log_status()
    }

    log_status(): void {
        console.log('LOG_STATUS');
        console.log('this.sidenav.mode=' + this.sidenav.mode);
        console.log('this.sidenav.opened=' + this.sidenav.opened);
        console.log('this.menuIsPinned=' + this.menuIsPinned);
        console.log('this.menuIsExpanded=' + this.menuIsExpanded);
    }

    // Valor inicial a aplicar a cada parametro de preferencias de usuario. El que haya en local storage o en su defecto el valor por defecto.
    initialParamValue(userPrefsParam: string): number {
        const userPreference_: any = this.localStorageService.getItem('usrPrefs_' + userPrefsParam);
        const userPreference: number = +userPreference_;   // Debe ser number, de otro modo, al recargar la pagina si ya esta grabado "usrPrefs_menuFontSize" en localstorage, al sumar pasa de 95 a "955", es decir, lo recupera como string, ya que userPreference_ no tiene tipo

        // console.log('userPreference ' + userPrefsParam + ' = ' + userPreference + ' typeof userPreference = ' + typeof userPreference);

        let def: number;
        switch (userPrefsParam) {
            case 'menuFontSize': {
                def = 100;
                break;
            }
            case 'menuIsPinned': {  // Cuidado con esto! Si cargamos un valor por defecto 1, nunca devolverá 0 en el return.
                def = def;
                break;
            }
            default: {    // Background color, image
                def = 1;
            }
        }

        return userPreference ? userPreference : def;
    }

    // Cambia el color de fondo del menu (rotando secuencialmente)
    rollsidenavBackgroundColor(): void {
        const numColors = 9;  // Numero total de clases css definidas para
        this.sidenavBackgroundColor += 1;
        if (this.sidenavBackgroundColor > numColors) {
            this.sidenavBackgroundColor = 1;
        }

        // chapu!
        if (this.sidenavBackgroundColor == 9) {  // white
            // PENDIENTE: dejar todos los links del menu mas oscuros. En vez de poner los colores a pelo en los css, deberiamos modificar los styles predefinidos
        }

        this.localStorageService.setItem('usrPrefs_sidenavBackgroundColor', JSON.stringify(this.sidenavBackgroundColor));      // Guardamos la preferencia del usuario en local storage
        console.log('this.sidenavBackgroundColor ' + this.sidenavBackgroundColor);
    }


    // Cambia la imagen de fondo del menu (rotando secuencialmente)
    rollsidenavBackgroundImage(): void {
        const numImages = 5;  // Numero total de clases css definidas para
        this.sidenavBackgroundImage += 1;
        if (this.sidenavBackgroundImage > numImages) {
            this.sidenavBackgroundImage = 1;
        }

        this.localStorageService.setItem('usrPrefs_sidenavBackgroundImage', JSON.stringify(this.sidenavBackgroundImage));      // Guardamos la preferencia del usuario en local storage
        // console.log('this.sidenavBackgroundImage ' + this.sidenavBackgroundImage)
    }

    zoomInMenuFont(): void {
        if (this.menuFontSize < 110) {
            this.menuFontSize += 5;  // +5%
        }

        this.localStorageService.setItem('usrPrefs_menuFontSize', JSON.stringify(this.menuFontSize));      // Guardamos la preferencia del usuario en local storage
        console.log('zoomInMenuFont()' + this.menuFontSize);
    }

    zoomOutMenuFont(): void {
        if (this.menuFontSize > 90) {
            this.menuFontSize -= 5;  // -5%
        }

        this.localStorageService.setItem('usrPrefs_menuFontSize', JSON.stringify(this.menuFontSize));      // Guardamos la preferencia del usuario en local storage
        console.log('zoomOutMenuFont()' + this.menuFontSize);
    }
}
