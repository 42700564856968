// General
export interface StringDictionary {
    [key: string]: string;
}

export const MAX_DATATABLE_REGS_BY_REQ = 20; // SINCRONIZAR CON BACK!!!

// AUTH
export const USER_ROLE = {
    S: 'Supervisor',
    M: 'Medical Staff',
    A: 'Administrative',
};

// Person
export const PERSON_TYPE_USER = 'U';
export const PERSON_TYPE_CLIENT = 'C';
export const PERSON_TYPE_TUTOR = 'T';

export const PERSON_TYPE = {
    U: 'User',
    C: 'Client',
    T: 'Tutor',
};

export const ORGANISATION_TYPE = {
    C: 'Company',
    I: 'Institution',
    F: 'Fundation',
    O: 'ONG',
    X: 'Organizar esto!',
};

export const CLIENT_TYPE_PERSON = 'P'; // Persona física
export const CLIENT_TYPE_ORGANISATION = 'O'; // Persona jurídica
export const CLIENT_TYPE_ICON = {
    CLIENT_TYPE_PERSON: 'face',
    CLIENT_TYPE_ORGANISATION_ICON: 'business',
};

export const PERSON_IDENTIFICACION_TYPES = {
    D: 'DNI',
    E: 'NIE',
    F: 'NIF',
    P: 'Passport'


    //R: 'Residence Card',
    //V: 'Driving License',
};

export const PERSON_TITLES: StringDictionary = {
    MR: 'Mr.',
    MS: 'Mrs.',
    /*   CT: "Count",
    MJ: "Your Majesty",
    YH: "Your highness",
   */
};

export const PERSON_STATUS_ACTIVE = 'A';

export const PERSON_STATUS = {
    A: 'Active',
    C: 'Cancelled',
};

export const TUTOR_PERSON_RELATIONS = {
    M: 'Mother',
    F: 'Father',
    T: 'Legal tutor',
};

// Agenda

// PENDIENTE: Estos temitas deberáin ir en el modulo de Agenda!
export const DAYS_OF_THE_WEEK = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
];

export const EVENT_RECURRING_PERIODS = {
    DW: 'Daily (monday to friday)',
    D: 'Daily',
    W: 'Weekly',
    M: 'Monthly',
    Y: 'Yearly',
};

export const EVENT_REMINDER_TYPES = {
    S: 'Screen notification',
    M: 'Email',
};

// En minutos por convenience, pero en principio no se utiliza como minutos, sólo es una codificación
export const EVENT_DURATION_OPTIONS = {
    5: '5 minutes',
    10: '10 minutes',
    20: '20 minutes',
    30: '30 minutes',
    45: '45 minutes',
    50: '50 minutes',
    60: '1 hour',
    120: '2 hours',
    180: '3 hours',
    240: '4 hours',
    300: '5 hours',
    360: '6 hours',
    420: '7 hours',
    480: '8 hours',
    540: '9 hour',
    600: '10 hours',
    1440: '1 day', // 60*24
    2880: '2 days',
    4320: '3 days',
    5760: '4 days',
    7200: '5 days',
    8640: '6 days',
    10080: '1 week', // 60*24*7
    20160: '2 weeks', // 60×24×7×2
    30240: '3 weeks', // 60×24×7×3
    43200: '1 month', // 60*24*30
    86400: '2 month', // 60*24*30*2
    129600: '3 month', // 60*24*30*3
    172800: '4 month', // 60*24*30*4
    216000: '5 month', // 60*24*30*5
    259200: '6 month', // 60*24*30*6
    525600: '1 year', // 60*24*365*1
    1051200: '2 years', // 60*24*365*2
};

export const TIME_INTERVALS_EN = {
    '00:00': '12:00 am',
    '00:15': '12:15 am',
    '00:30': '12:30 am',
    '00:45': '12:45 am',
    '01:00': '1:00 am',
    '01:15': '1:15 am',
    '01:30': '1:30 am',
    '01:45': '1:45 am',
    '02:00': '2:00 am',
    '02:15': '2:15 am',
    '02:30': '2:30 am',
    '02:45': '2:45 am',
    '03:00': '3:00 am',
    '03:15': '3:15 am',
    '03:30': '3:30 am',
    '03:45': '3:45 am',
    '04:00': '4:00 am',
    '04:15': '4:15 am',
    '04:30': '4:30 am',
    '04:45': '4:45 am',
    '05:00': '5:00 am',
    '05:15': '5:15 am',
    '05:30': '5:30 am',
    '05:45': '5:45 am',
    '06:00': '6:00 am',
    '06:15': '6:15 am',
    '06:30': '6:30 am',
    '06:45': '6:45 am',
    '07:00': '7:00 am',
    '07:15': '7:15 am',
    '07:30': '7:30 am',
    '07:45': '7:45 am',
    '08:00': '8:00 am',
    '08:15': '8:15 am',
    '08:30': '8:30 am',
    '08:45': '8:45 am',
    '09:00': '9:00 am',
    '09:15': '9:15 am',
    '09:30': '9:30 am',
    '09:45': '9:45 am',
    '10:00': '10:00 am',
    '10:15': '10:15 am',
    '10:30': '10:30 am',
    '10:45': '10:45 am',
    '11:00': '11:00 am',
    '11:15': '11:15 am',
    '11:30': '11:30 am',
    '11:45': '11:45 am',
    '12:00': '12:00 pm',
    '12:15': '12:15 pm',
    '12:30': '12:30 pm',
    '12:45': '12:45 pm',
    '13:00': '1:00 pm',
    '13:15': '1:15 pm',
    '13:30': '1:30 pm',
    '13:45': '1:45 pm',
    '14:00': '2:00 pm',
    '14:15': '2:15 pm',
    '14:30': '2:30 pm',
    '14:45': '2:45 pm',
    '15:00': '3:00 pm',
    '15:15': '3:15 pm',
    '15:30': '3:30 pm',
    '15:45': '3:45 pm',
    '16:00': '4:00 pm',
    '16:15': '4:15 pm',
    '16:30': '4:30 pm',
    '16:45': '4:45 pm',
    '17:00': '5:00 pm',
    '17:15': '5:15 pm',
    '17:30': '5:30 pm',
    '17:45': '5:45 pm',
    '18:00': '6:00 pm',
    '18:15': '6:15 pm',
    '18:30': '6:30 pm',
    '18:45': '6:45 pm',
    '19:00': '7:00 pm',
    '19:15': '7:15 pm',
    '19:30': '7:30 pm',
    '19:45': '7:45 pm',
    '20:00': '8:00 pm',
    '20:15': '8:15 pm',
    '20:30': '8:30 pm',
    '20:45': '8:45 pm',
    '21:00': '9:00 pm',
    '21:15': '9:15 pm',
    '21:30': '9:30 pm',
    '21:45': '9:45 pm',
    '22:00': '10:00 pm',
    '22:15': '10:15 pm',
    '22:30': '10:30 pm',
    '22:45': '10:45 pm',
    '23:00': '11:00 pm',
    '23:15': '11:15 pm',
    '23:30': '11:30 pm',
    '23:45': '11:45 pm',
};

export const TIME_INTERVALS_ES = {
    '00:00': '00:00',
    '00:15': '00:15',
    '00:30': '00:30',
    '00:45': '00:45',
    '01:00': '01:00',
    '01:15': '01:15',
    '01:30': '01:30',
    '01:45': '01:45',
    '02:00': '02:00',
    '02:15': '02:15',
    '02:30': '02:30',
    '02:45': '02:45',
    '03:00': '03:00',
    '03:15': '03:15',
    '03:30': '03:30',
    '03:45': '03:45',
    '04:00': '04:00',
    '04:15': '04:15',
    '04:30': '04:30',
    '04:45': '04:45',
    '05:00': '05:00',
    '05:15': '05:15',
    '05:30': '05:30',
    '05:45': '05:45',
    '06:00': '06:00',
    '06:15': '06:15',
    '06:30': '06:30',
    '06:45': '06:45',
    '07:00': '07:00',
    '07:15': '07:15',
    '07:30': '07:30',
    '07:45': '07:45',
    '08:00': '08:00',
    '08:15': '08:15',
    '08:30': '08:30',
    '08:45': '08:45',
    '09:00': '09:00',
    '09:15': '09:15',
    '09:30': '09:30',
    '09:45': '09:45',
    '10:00': '10:00',
    '10:15': '10:15',
    '10:30': '10:30',
    '10:45': '10:45',
    '11:00': '11:00',
    '11:15': '11:15',
    '11:30': '11:30',
    '11:45': '11:45',
    '12:00': '12:00',
    '12:15': '12:15',
    '12:30': '12:30',
    '12:45': '12:45',
    '13:00': '13:00',
    '13:15': '13:15',
    '13:30': '13:30',
    '13:45': '13:45',
    '14:00': '14:00',
    '14:15': '14:15',
    '14:30': '14:30',
    '14:45': '14:45',
    '15:00': '15:00',
    '15:15': '15:15',
    '15:30': '15:30',
    '15:45': '15:45',
    '16:00': '16:00',
    '16:15': '16:15',
    '16:30': '16:30',
    '16:45': '16:45',
    '17:00': '17:00',
    '17:15': '17:15',
    '17:30': '17:30',
    '17:45': '17:45',
    '18:00': '18:00',
    '18:15': '18:15',
    '18:30': '18:30',
    '18:45': '18:45',
    '19:00': '19:00',
    '19:15': '19:15',
    '19:30': '19:30',
    '19:45': '19:45',
    '20:00': '20:00',
    '20:15': '20:15',
    '20:30': '20:30',
    '20:45': '20:45',
    '21:00': '21:00',
    '21:15': '21:15',
    '21:30': '21:30',
    '21:45': '21:45',
    '22:00': '22:00',
    '22:15': '22:15',
    '22:30': '22:30',
    '22:45': '22:45',
    '23:00': '23:00',
    '23:15': '23:15',
    '23:30': '23:30',
    '23:45': '23:45',
};



// Invoiving

export const BUDGET_STATUS = {
    A: 'Active',
    C: 'Cancelled',
};

export const INVOICE_STATUS = {
    A: 'Active',
    P: 'Proforma',
    O: 'Paid',
    C: 'Cancelled',
};

// Product

export const PRODUCT_STATUS = {
    A: 'Active',
    C: 'Cancelled',
};

// Service

export const SERVICE_STATUS = {
    A: 'Active',
    C: 'Cancelled',
};

export const SERVICE_INVOICING_MODE = {
    F: 'Fixed price',
    H: 'Price per hour',
};

export const CLIENT_TYPE_OPTIONS = {
    P: 'Person',
    C: 'Company',
};

export const CLIENT_STATUS_OPTIONS = {
    A: 'Active',
    P: 'Pending',
    C: 'Cancelled',
};
