<div class="user-menu">

	<button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" mat-icon-button>
		<!-- PENDIENTE: Traer esta imagen del back -->
		<img src="/assets/img/users/{{userCompanyId}}/{{userId}}.jpg"/>
	</button>

	<mat-menu #menu="matMenu">
		<button mat-menu-item>
			<mat-icon>update</mat-icon>
			<a routerLink="/presence-control/entry/"><span>Presence control</span></a>
			<!--PENDIENTE: Linkar con un componente que decida si toca fichar para entrar o para salir en funcion de la situación actual del usuario-->
		</button>
		<mat-divider></mat-divider>
		<button disabled mat-menu-item>
			<mat-icon>mode_edit_outline</mat-icon>
			<span>Perfil</span>
		</button>
		<button mat-menu-item>
			<mat-icon>settings</mat-icon>
			<span>Settings</span>
		</button>
		<button [matMenuTriggerFor]="language" mat-menu-item>
			<mat-icon>language</mat-icon>
			<span>Language</span>
		</button>
		<button mat-menu-item>
			<mat-icon>style</mat-icon>
			<span>Styles</span>
		</button>
		<mat-divider></mat-divider>
		<button mat-menu-item>
			<mat-icon>power_settings_new</mat-icon>
			<a routerLink="/login/"><span>Logout</span></a>
		</button>
	</mat-menu>

	<mat-menu #language="matMenu">
		<button (click)="setLanguage('es')" mat-menu-item>
			<span>Spanish</span>
		</button>
		<button (click)="setLanguage('en')" mat-menu-item>
			<span>English</span>
		</button>
	</mat-menu>
</div>
